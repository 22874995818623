import React from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	IconButton,
	Typography,
} from '@mui/material';
import { CloseRounded, ZoomIn, ZoomOut } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { Line } from '../../styles';
import { usePO } from '../../utils/POContext';
import {
	MiniMap,
	TransformWrapper,
	TransformComponent,
} from 'react-zoom-pan-pinch';

interface Props {
	open: boolean;
	setOpen: any;
	imageIndexToShow?: any;
	images: any;
}

const FullSizeImageDialog: React.FC<Props> = ({
	open,
	setOpen,
	imageIndexToShow,
	images,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();

	const handleClose = () => {
		setOpen(false);
	};

	const imageElement = (
		<img
			alt="imagem"
			src={imageIndexToShow ? images[imageIndexToShow] : images}
			style={{ maxWidth: '80vw', maxHeight: '80vh' }}
		/>
	);

	return (
		<Dialog
			data-testid="FullSizeImageModal"
			open={open}
			maxWidth="xl"
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			slotProps={{
				backdrop: {
					style: {
						backdropFilter: 'blur(15px)',
					},
				},
			}}
			PaperProps={{
				style: {
					backgroundColor: '#ffffff03',
				},
			}}
		>
			<DialogContent style={{ padding: 5 }}>
				<Line
					style={{
						justifyContent: 'space-between',
						position: 'fixed',
						top: 0,
						left: 0,
						zIndex: 300,
					}}
				>
					<Typography
						variant="subtitle2"
						color="primary"
						style={{
							margin: 15,
							opacity: 0.7,
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primary,
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.vertMenuColor
									: selectedTheme.foreground,
							borderRadius: 4,
							padding: '4px 8px',
						}}
					>
						{`${t("Classificações.Pressione 'Esc' para sair")}`}
					</Typography>
					<IconButton
						onClick={handleClose}
						style={{
							width: 30,
							opacity: 0.7,
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.vertMenuColor
									: selectedTheme.foreground,
							borderRadius: 4,
							padding: '4px 0',
							marginRight: 15,
						}}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<CloseRounded
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primary,
							}}
						/>
					</IconButton>
				</Line>

				<TransformWrapper>
					{({ zoomIn, zoomOut, resetTransform }) => (
						<div
							style={{
								justifyContent: 'center',
								display: 'flex',
								alignItems: 'center',
								alignContent: 'center',
								flexDirection: 'column',
							}}
						>
							<Line
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									flexDirection: 'row',
									gap: 10,
									padding: '10px',
								}}
							>
								<Button
									size="small"
									onClick={() => zoomIn()}
									style={{
										border: `1px solid ${
											selectedTheme.id === 'dark'
												? selectedTheme.footerLine
												: 'transparent'
										}`,
										color:
											selectedTheme.tone === 'dark'
												? selectedTheme.textColorHigh
												: selectedTheme.foreground,
										background: selectedTheme.primaryDark,
										gap: 5,
									}}
								>
									{t('Classificações.Ampliar')}
									<ZoomIn />
								</Button>

								<Button
									size="small"
									onClick={() => zoomOut()}
									style={{
										border: `1px solid ${
											selectedTheme.id === 'dark'
												? selectedTheme.footerLine
												: 'transparent'
										}`,
										color:
											selectedTheme.tone === 'dark'
												? selectedTheme.textColorHigh
												: selectedTheme.foreground,
										background: selectedTheme.primaryDark,
										gap: 5,
									}}
								>
									{t('Classificações.Reduzir')}
									<ZoomOut />
								</Button>

								<Button
									size="small"
									onClick={() => resetTransform()}
									style={{
										border: `1px solid ${
											selectedTheme.id === 'dark'
												? selectedTheme.footerLine
												: 'transparent'
										}`,
										color:
											selectedTheme.tone === 'dark'
												? selectedTheme.textColorHigh
												: selectedTheme.foreground,
										background: selectedTheme.primaryDark,
									}}
								>
									{t('Classificações.Resetar')}
								</Button>
							</Line>

							<div
								style={{
									position: 'fixed',
									zIndex: 5,
									top: '63px',
									right: '18%',
								}}
							>
								<MiniMap width={200}>{imageElement}</MiniMap>
							</div>

							<TransformComponent>{imageElement}</TransformComponent>
						</div>
					)}
				</TransformWrapper>
			</DialogContent>
		</Dialog>
	);
};

export default FullSizeImageDialog;
