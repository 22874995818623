import React, { useState } from 'react';
import {
	IconButton,
	Zoom,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	TextField,
	Divider,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
} from '@mui/material';
import {
	BorderColorRounded,
	CloseRounded,
	Delete,
	LaunchOutlined,
} from '@mui/icons-material/';
import { api, isAxiosError, setupRequestToken } from '../../../../utils/api';
import { Column, Line, RegularPanel } from '../../../../styles';
import { OfferToolTip } from '../../../../helpers';
import { useAuth0 } from '../../../../react-auth0-spa';
import { usePO } from '../../../../utils/POContext';
import { useTranslation } from 'react-i18next';

import FileCard from '../../../../components/FileCard';
import FormDivider from '../../../../components/FormDivider';
import FullSizeImageDialog from '../../../../components/FullSizeImageModal';
import LoadingDots from '../../../../components/LoadingDots';
import Panel from '../../../../components/Panel';
import { makeStyles } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import { ErrorResponse, PlatformOptionsType } from '../../../../interfaces';
interface Props {
	rowData: any;
	refresh: () => void;
	platformTypeOptions: PlatformOptionsType[];
}

const EditPlatformModal: React.FC<Props> = ({
	rowData,
	refresh,
	platformTypeOptions,
}: Props) => {
	const { selectedTheme, cursorMode } = usePO();
	const { t } = useTranslation();
	const { token } = useAuth0();
	setupRequestToken(api, token);

	const [openFileModal, setOpenFileModal] = useState(false);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = React.useState(false);

	const [selectedPlatform, setSelectedPlatform] = useState<any>(rowData?.name);
	const [selectedCountry, setSelectedCountry] = useState<any>(
		rowData?.country || null
	);
	const [description, setDescription] = useState<string>(
		rowData?.description || ''
	);
	const [selectPlatformType, setSelectPlatformType] = useState<any>(
		rowData?.platformType
	);
	const [uploadedFile, setUploadedFile] = useState<any>(null);

	const handleClose = () => {
		setOpen(false);
	};

	const handleSelectPlatformChange = (event) => {
		setSelectPlatformType(event.target.value);
	};

	const handleDescriptionChange = (event) => {
		setDescription(event.target.value);
	};

	const formatFile = {
		file: rowData.logo !== null ? rowData.logo : '',
		name: `${rowData?.name.toLowerCase().split(' ').join('-')}.png`,
		preview: rowData?.logo,
	};

	const handleOpen = () => {
		setSelectedPlatform(rowData?.name);
		setSelectedCountry(rowData?.country);
		setDescription(rowData?.description);
		setSelectPlatformType(rowData?.platformType);
		setUploadedFile(formatFile);
		setOpen(true);
	};

	const handleSendData = async () => {
		try {
			setLoading(true);
			const formData = new FormData();
			let platformResponse;
			if (uploadedFile?.file !== rowData?.logo) {
				formData.append('uploadFile', uploadedFile.file, uploadedFile.name);
				platformResponse = await api.put(
					`platforms/${rowData?.platformID}?name=${selectedPlatform}&platformTypeID=${selectPlatformType}&description=${description}`,
					formData
				);
			} else {
				platformResponse = await api.put(
					`platforms/${rowData?.platformID}?name=${selectedPlatform}&platformTypeID=${selectPlatformType}&description=${description}`
				);
			}

			enqueueSnackbar(t(platformResponse.data.detail), { variant: 'success' });
			setLoading(false);
			handleClose();
			refresh();
		} catch (error) {
			setLoading(false);
			console.log(`error:`, error);
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse.data.detail, { variant: 'error' });
				}
			}
		}
	};

	const useStyles = makeStyles(() => ({
		root: {
			background:
				selectedTheme.id === 'dark'
					? selectedTheme.overlay4dp
					: selectedTheme.foreground,
		},
		editTypography: {
			color:
				selectedTheme.id === 'dark'
					? selectedTheme.textColorHigh
					: selectedTheme.id === 'main'
						? selectedTheme.primary
						: selectedTheme.foreground,
		},
	}));

	const handleThemeHeaderTitle = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const classes = useStyles();

	const renderAction = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Plataformas.Editar Plataforma')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={() => rowData && handleOpen()}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<BorderColorRounded
					style={{
						fontSize: 21,
						marginBottom: -2,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const renderHeader = () => (
		<>
			<DialogTitle
				style={{
					height: 60,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
					color: '#FFFFFF',
					display: 'flex',
				}}
				id="max-width-dialog-title"
			>
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					<Typography
						noWrap
						classes={{ root: classes.editTypography }}
						style={{
							fontSize: 20,
							fontWeight: 'bold',
							maxWidth: '90%',
						}}
					>
						{`${t('Buscas.Tabs.Plataformas.Editar Plataforma')}: ${
							rowData?.name
						}`}
					</Typography>
					<IconButton
						onClick={handleClose}
						style={{ marginRight: '-16px' }}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<CloseRounded
							style={{
								color: handleThemeHeaderTitle(),
							}}
						/>
					</IconButton>
				</Line>
			</DialogTitle>
		</>
	);

	const renderDialogContent = () => (
		<DialogContent
			style={{ padding: '0px 0px' }}
			classes={{
				root: classes.root,
			}}
		>
			<Divider
				style={{
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.footerLine,
				}}
			/>
			<Divider
				style={{
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.footerLine,
				}}
			/>
			<RegularPanel
				style={{
					backgroundColor:
						selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
				}}
			>
				<Panel
					noMargin
					open
					title={t('Buscas.Tabs.Plataformas.Informações Básicas')}
				>
					<Column>
						<TextField
							value={selectedPlatform}
							label={t('Buscas.Tabs.Plataformas.Nome da plataforma')}
							size="small"
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							inputProps={{
								readOnly: true,
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorMedium
											: '',
								},
							}}
							sx={{
								width: '100%',
								'& .MuiFormLabel-root': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								'.MuiSvgIcon-root ': {
									fill:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							}}
						/>
						<TextField
							value={selectedCountry}
							label={t('Buscas.Tabs.Plataformas.País')}
							size="small"
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							inputProps={{
								readOnly: true,
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorMedium
											: '',
								},
							}}
							sx={{
								width: '100%',
								marginTop: 3,
								'& .MuiFormLabel-root': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								'.MuiSvgIcon-root ': {
									fill:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							}}
						/>
						<FormControl
							size="small"
							fullWidth
							sx={{
								width: '100%',
								marginTop: '15px',
								'.MuiOutlinedInput-notchedOutline': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								'&:hover .MuiOutlinedInput-notchedOutline': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							}}
						>
							<InputLabel
								sx={{
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							>
								{t('Buscas.Tabs.Plataformas.Tipo da plataforma')}
							</InputLabel>
							<Select
								label={t('Buscas.Tabs.Plataformas.Tipo da plataforma')}
								name="platformTypeID"
								value={selectPlatformType}
								onChange={handleSelectPlatformChange}
								sx={{
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
									'.MuiSelect-select': {
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.overlay3dp
												: selectedTheme.foreground,
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
										'&:hover': {
											background:
												selectedTheme.id === 'dark'
													? selectedTheme.overlay6dp
													: '',
										},
									},
									'.MuiSelect-icon': {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									},
									'& [aria-expanded=true]': {
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.overlay6dp
												: '',
									},
								}}
								inputProps={{
									MenuProps: {
										PaperProps: {
											sx: {
												background:
													selectedTheme.id === 'dark'
														? selectedTheme.overlay3dp
														: selectedTheme.foreground,
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorHigh
														: '',
											},
										},
									},
								}}
							>
								{platformTypeOptions.map((option: any) => (
									<MenuItem key={option?.id} value={option?.id}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormDivider
							name={t('Buscas.Tabs.Plataformas.Descrição')}
							background={
								selectedTheme.id === 'dark' && selectedTheme.overlay6dp
							}
						/>
						<TextField
							value={description}
							variant="outlined"
							multiline
							rows="2"
							autoComplete="off"
							onChange={handleDescriptionChange}
							inputProps={{
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorMedium
											: '',
								},
							}}
							sx={{
								'& .MuiOutlinedInput-root': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								width: '100%',
								'& .MuiInputBase-root': {
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
							}}
						/>
					</Column>
				</Panel>
			</RegularPanel>
			<RegularPanel
				style={{
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
				}}
			>
				<Panel open title={t('Buscas.Tabs.Plataformas.Logomarca')}>
					<Line
						style={{
							width: '100%',
						}}
					>
						<FileCard
							setUploadedFile={setUploadedFile}
							customMessage={
								uploadedFile
									? uploadedFile?.file !== rowData?.logo
										? `${uploadedFile?.name} ${t(
												'Buscas.Tabs.Plataformas.renomeado para'
											)} ➜ ${rowData?.name
												.toLowerCase()
												.split(' ')
												.join('-')}.png`
										: `${uploadedFile?.name}`
									: t(
											'Buscas.Tabs.Plataformas.Clique no ícone ou arraste um arquivo para cá'
										)
							}
							cardActions
							actions1={actionElement1()}
							actions2={actionElement2()}
						/>
						<FullSizeImageDialog
							open={openFileModal}
							setOpen={setOpenFileModal}
							imageIndexToShow={0}
							images={uploadedFile === null ? '' : uploadedFile?.preview}
						/>
					</Line>
				</Panel>
			</RegularPanel>
		</DialogContent>
	);

	const actionElement1 = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Plataformas.Deletar arquivo')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={() => setUploadedFile(null)}
				disabled={uploadedFile === null}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Delete
					style={{
						fontSize: 23,
						color: selectedTheme.error,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const actionElement2 = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Plataformas.Visualizar arquivo')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={() => setOpenFileModal(true)}
				disabled={uploadedFile === null}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<LaunchOutlined
					style={{
						fontSize: 23,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Buscas.Tabs.Plataformas.Cancelar')}
				</Button>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						type="button"
						size="small"
						onClick={handleSendData}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
							background:
								selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: selectedTheme.foreground
							}`,
						}}
					>
						{t('Buscas.Tabs.Plataformas.Salvar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<>
			{renderAction()}
			<Dialog
				open={open}
				aria-label="confirm-edit-dialog"
				fullWidth
				maxWidth="md"
				disableEscapeKeyDown
				scroll="body"
				style={{ cursor: cursorMode }}
			>
				{renderHeader()}
				{renderDialogContent()}
				{renderFooter()}
			</Dialog>
		</>
	);
};

export default EditPlatformModal;
