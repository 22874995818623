import { ClientType, DashboardApiResponses } from '../../interfaces';
import { api } from '../../utils/api';

export const fetchGetAllDashboardData = async (
	selectedClient: ClientType,
	selectedStartDate: string,
	selectedFinalDate: string,
	platformOfferFirstDate: string,
	today: string
) => {
	const timeout = 60 * 10000; // 10 minutes

	const apiEndpoints: { [K in keyof DashboardApiResponses]: string } = {
		UserClassificationResponse: `/reports/clients/metrics/classifications?clientID=${selectedClient.id}&startDate=${'2024-02-09T03:00:00.000Z'}&finalDate=${selectedFinalDate}&denounceStatusID=CLASSIFICADA&classificationTypeID=SUSPT`,
		UserClassificationByClientResponse: `/reports/clients/metrics/classifications?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}&denounceStatusID=CLASSIFICADA&denounceStatusID=NOTIFICADA&denounceStatusID=REMOVIDA`,
		UserClassificationTotalProfilesResponse: `/reports/clients/metrics/classification-total-profiles?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`,
		UserClassificationByStatusResponse: `/reports/clients/metrics/classifications?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}&denounceStatusID=REMOVIDA`,
		UserClassificationTotalFollowersResponse: `/reports/clients/metrics/classification-total-followers?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`,
		CalendarChartResponse: `/reports/users/metrics/user-daily-denouncements?startDate=${platformOfferFirstDate}&finalDate=${today}`,
		LeftBarChartResponse: `/reports/clients/metrics/classification-by-country?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`,
		RightBarChartResponse: `/reports/clients/metrics/classification-by-platform?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`,
		AnalystCalendarChartResponse: `/reports/users/metrics/user-daily-classifications?startDate=${platformOfferFirstDate}&finalDate=${today}`,
		RightBarChartAnalystResponse: `/reports/users/metrics/clients/tags?startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`,
		LeftBarChartAnalystResponse: `/reports/users/metrics/clients/platformINcountry?startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`,
		PlatformTypePieChartResponse: `/reports/clients/metrics/classification-by-platformType?clientID=${selectedClient.id}&startDate=${selectedStartDate}&finalDate=${selectedFinalDate}`,
	};

	const dashboardData: DashboardApiResponses = {
		UserClassificationResponse: null,
		UserClassificationByClientResponse: null,
		UserClassificationTotalProfilesResponse: null,
		UserClassificationByStatusResponse: null,
		UserClassificationTotalFollowersResponse: null,
		CalendarChartResponse: null,
		LeftBarChartResponse: null,
		RightBarChartResponse: null,
		AnalystCalendarChartResponse: null,
		RightBarChartAnalystResponse: null,
		LeftBarChartAnalystResponse: null,
		PlatformTypePieChartResponse: null,
	};

	const promises = Object.values(apiEndpoints).map((endpoint) =>
		api.get(endpoint, { timeout: timeout })
	);

	const results = await Promise.allSettled(promises);

	Object.keys(apiEndpoints).forEach((key, index) => {
		const result = results[index];

		dashboardData[key as keyof DashboardApiResponses] = {
			data: result.status === 'fulfilled' ? result.value?.data : null,
			status:
				result.status === 'fulfilled'
					? result.value.status
					: result.reason.response?.status || result.status,
			detail:
				result.status === 'fulfilled'
					? result.value.statusText
					: result.reason.response?.data.detail || result.reason.message,
		};
	});

	return dashboardData;
};
