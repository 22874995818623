import React from 'react';
import { Card, Grid, Typography, Zoom } from '@mui/material';
import { Column, Line } from '../../../../../styles';
import Chart from 'react-google-charts';
import { usePO } from '../../../../../utils/POContext';
import { handleNumberAbbreviation, OfferToolTip } from '../../../../../helpers';
import { t } from 'i18next';
import BackdropLoading from '../../../../../components/BackdropLoading';

interface Props {
	loading: boolean;
	pieChartData: any;
	DetectedProfiles: any;
	ProfileFollowers: any;
	TotalViolations: any;
}

const MiddleMainContent: React.FC<Props> = ({
	loading,
	pieChartData,
	DetectedProfiles,
	ProfileFollowers,
	TotalViolations,
}: Props) => {
	const { selectedTheme, userData } = usePO();

	const renderDistributionInfractions = () => {
		const cardBackgroundColor =
			selectedTheme.id === 'dark' ? selectedTheme.gradient : '#fafafa';

		const sliceColors = (() => {
			if (selectedTheme.id === 'dark') {
				return ['#D1DD26', '#80D8BA'];
			} else if (selectedTheme.id === 'main') {
				return ['#D1DD26', '#80D8BA'];
			} else if (selectedTheme.id === 'summer') {
				return ['#dac242', '#ab5632'];
			} else if (selectedTheme.id === 'winter') {
				return ['#6E3CB9', '#4984DD'];
			} else if (selectedTheme.id === 'red') {
				return ['#881F37', '#4a3c4e'];
			} else {
				return ['#1998A0', '#204E51'];
			}
		})();

		const chartOptions = {
			backgroundColor: 'transparent',
			slices: [{ color: sliceColors[0] }, { color: sliceColors[1] }],
			legend: {
				position: 'left',
				alignment: 'center',
				textStyle: {
					fontSize: 16,
					bold: true,
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: '#000000',
				},
			},
			chartArea: {
				width: '80%',
				height: '80%',
			},
			pieSliceTextStyle: {
				fontSize: 18,
				color:
					selectedTheme.id === 'dark' || selectedTheme.id === 'main'
						? '#000000'
						: selectedTheme.foreground,
			},
		};

		const emptyData = [
			['Categoria', 'Valor'],
			[t('Inicio.Dashboard.Sem dados para o período selecionado'), 1],
		];

		return (
			<Card
				sx={{
					backgroundColor: cardBackgroundColor,
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
					'&:hover': {
						boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
					},
				}}
			>
				<Line
					style={{
						width: '100%',
						justifyContent: 'flex-start',
						padding: '20px 20px 0px',
					}}
				>
					<Typography
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: '#000000',
						}}
					>
						{t('Inicio.Dashboard.Infrações por marketplaces e redes sociais')}
					</Typography>
				</Line>
				<Line style={{ width: '100%', justifyContent: 'center' }}>
					{pieChartData && pieChartData?.length > 1 ? (
						<div
							style={{
								width: '100%',
								backgroundColor: cardBackgroundColor,
							}}
						>
							<Chart
								chartType="PieChart"
								width="100%"
								height="300px"
								data={pieChartData}
								options={chartOptions}
							/>
						</div>
					) : (
						<div
							style={{
								width: '100%',
								backgroundColor: cardBackgroundColor,
							}}
						>
							<Chart
								chartType="PieChart"
								width="100%"
								height="300px"
								data={emptyData}
								options={{
									...chartOptions,
									pieSliceText: 'none',
								}}
							/>
						</div>
					)}
				</Line>
			</Card>
		);
	};

	const renderRemovals = () => {
		return (
			<Card
				sx={{
					backgroundColor:
						selectedTheme.id === 'dark' ? selectedTheme.gradient : '#fafafa',
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
					'&:hover': {
						boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
					},
				}}
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
				}}
			>
				<Column style={{ rowGap: 10 }}>
					<OfferToolTip
						placement="top"
						title={TotalViolations.toLocaleString(userData.i18nID)}
						arrow
						enterDelay={200}
						enterNextDelay={200}
						TransitionComponent={Zoom}
					>
						<Typography
							variant="h2"
							sx={{
								marginBottom: '-5px',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{handleNumberAbbreviation(userData?.i18nID, TotalViolations)}
						</Typography>
					</OfferToolTip>
					<OfferToolTip
						title={t('Inicio.Dashboard.Total de infrações')}
						placement="bottom"
						arrow
						enterDelay={200}
						enterNextDelay={200}
						TransitionComponent={Zoom}
					>
						<Typography
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t('Inicio.Dashboard.Total de infrações')}
						</Typography>
					</OfferToolTip>
				</Column>
			</Card>
		);
	};

	const renderAuthors = () => {
		return (
			<Card
				sx={{
					backgroundColor:
						selectedTheme.id === 'dark' ? selectedTheme.gradient : '#fafafa',
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
					'&:hover': {
						boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
					},
				}}
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
				}}
			>
				<Column style={{ rowGap: 10 }}>
					<OfferToolTip
						placement="top"
						title={DetectedProfiles.toLocaleString(userData.i18nID)}
						arrow
						enterDelay={200}
						enterNextDelay={200}
						TransitionComponent={Zoom}
					>
						<Typography
							variant="h2"
							sx={{
								marginBottom: '-5px',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{handleNumberAbbreviation(userData?.i18nID, DetectedProfiles)}
						</Typography>
					</OfferToolTip>
					<OfferToolTip
						title={t('Inicio.Dashboard.Total de perfis detectados')}
						placement="bottom"
						arrow
						enterDelay={200}
						enterNextDelay={200}
						TransitionComponent={Zoom}
					>
						<Typography
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t('Inicio.Dashboard.Total de perfis detectados')}
						</Typography>
					</OfferToolTip>
				</Column>
			</Card>
		);
	};

	const renderFollowers = () => {
		return (
			<Card
				sx={{
					backgroundColor:
						selectedTheme.id === 'dark' ? selectedTheme.gradient : '#fafafa',
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
					'&:hover': {
						boxShadow: '1px 1px 4px 1px rgba(160,160,160,0.9)',
					},
				}}
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
				}}
			>
				<Column style={{ rowGap: 10 }}>
					<OfferToolTip
						placement="top"
						title={ProfileFollowers.toLocaleString(userData.i18nID)}
						arrow
						enterDelay={200}
						enterNextDelay={200}
						TransitionComponent={Zoom}
					>
						<Typography
							variant="h2"
							sx={{
								marginBottom: '-5px',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{handleNumberAbbreviation(userData?.i18nID, ProfileFollowers)}
						</Typography>
					</OfferToolTip>
					<OfferToolTip
						title={t('Inicio.Dashboard.Seguidores dos perfis')}
						placement="bottom"
						arrow
						enterDelay={200}
						enterNextDelay={200}
						TransitionComponent={Zoom}
					>
						<Typography
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t('Inicio.Dashboard.Seguidores dos perfis')}
						</Typography>
					</OfferToolTip>
				</Column>
			</Card>
		);
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					{renderDistributionInfractions()}
				</Grid>
				<Grid item xs={12} md={6}>
					<Grid container spacing={2} style={{ height: '100%' }}>
						<Grid item xs={12} md={12} style={{ height: '60%' }}>
							{renderRemovals()}
						</Grid>
						<Grid item xs={12} md={6} style={{ height: '45%' }}>
							{renderAuthors()}
						</Grid>
						<Grid item xs={12} md={6} style={{ height: '45%' }}>
							{renderFollowers()}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{loading ? <BackdropLoading /> : null}
		</>
	);
};

export default MiddleMainContent;
