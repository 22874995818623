import React, { useEffect, useState } from 'react';
import { Checkbox, Typography } from '@mui/material';
import { Info } from '@mui/icons-material/';
import { DataSearch } from '@appbaseio/reactivesearch';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../styles';
import { OfferToolTip } from '../../../helpers';
import { usePO } from '../../../utils/POContext';
import FormDivider from '../../FormDivider';
import { useDispatch, useSelector } from 'react-redux';
import { updateClassificationsFilters } from '../../../redux/slices/filterSlice';

interface Props {
	checkboxDenouncementsItemsField?: boolean;
	checkboxTagIDItemsField?: boolean;
	checkboxAllowedItemsField?: boolean;
	keyLogic: any;
	viewDenouncements: string;
	setViewDenouncements: (data: string) => void;
	viewDenouncementItems: boolean;
	setViewDenouncementItems: (data: boolean) => void;
	viewAllowedItems: boolean;
	setViewAllowedItems: (data: boolean) => void;
	viewTagIDItems: boolean;
	setViewTagIDItems: (data: boolean) => void;
}

const ViewDenouncementItems: React.FC<Props> = ({
	checkboxDenouncementsItemsField,
	checkboxTagIDItemsField,
	checkboxAllowedItemsField,
	setViewDenouncements,
	viewDenouncements,
	setViewDenouncementItems,
	viewDenouncementItems,
	viewAllowedItems,
	setViewAllowedItems,
	viewTagIDItems,
	setViewTagIDItems,
}) => {
	const { t } = useTranslation();
	const { selectedClient, selectedTheme } = usePO();
	const [, setNewKey] = useState(0);

	const dispatch = useDispatch();
	const filtersRedux = useSelector((state: any) => state.filters);

	const [checkBoxStateTagID, setCheckBoxStateTagID] = useState(false);

	const [checkBoxStateAllowedItems, setCheckBoxStateAllowedItems] =
		useState(false);

	const [checkBoxStateDenouncementItems, setCheckBoxStateDenouncementItems] =
		useState(false);

	useEffect(() => {
		setCheckBoxStateTagID(
			filtersRedux.classifications.defaultFilters.viewTagIDItems
		);
		setCheckBoxStateAllowedItems(
			filtersRedux.classifications.defaultFilters.viewAllowedItems
		);

		const initialViewDenouncementItems =
			filtersRedux.classifications.defaultFilters.viewDenouncementItems;
		setCheckBoxStateDenouncementItems(initialViewDenouncementItems);
		setViewDenouncements(
			initialViewDenouncementItems ? selectedClient?.id : ''
		);
	}, [
		filtersRedux.viewDenouncementItems,
		filtersRedux.viewTagIDItems,
		filtersRedux.viewAllowedItems,
	]);

	const incrementNewKey = () => {
		setNewKey((prevKey) => prevKey + 1);
	};

	const handleSetViewAllowedItems = (isChecked) => {
		setViewAllowedItems(isChecked);
		if (isChecked) {
			setViewTagIDItems(false);
			setViewDenouncementItems(false);
		}
		dispatch(
			updateClassificationsFilters({
				filterName: 'defaultFilters',
				filterData: {
					...filtersRedux.classifications.defaultFilters,
					viewAllowedItems: isChecked,
					viewTagIDItems: !isChecked
						? filtersRedux.classifications.defaultFilters.viewTagIDItems
						: false,
					viewDenouncementItems: !isChecked
						? filtersRedux.classifications.defaultFilters.viewDenouncementItems
						: false,
				},
			})
		);
		incrementNewKey();
	};

	const handleChangeViewAllowedItems = () => {
		const newCheckBoxState = !checkBoxStateAllowedItems;
		setCheckBoxStateAllowedItems(newCheckBoxState);
		handleSetViewAllowedItems(newCheckBoxState);
	};

	const renderViewAllowedItems = () => (
		<Line>
			<Checkbox
				key={`checkAllowedItens-${viewAllowedItems}`}
				data-testid="viewAllowedItems"
				aria-label="tour"
				checked={checkBoxStateAllowedItems}
				onClick={handleChangeViewAllowedItems}
				color="primary"
				style={{
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.primaryDark,
				}}
			/>
			<Typography
				style={{
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.primary,
				}}
			>
				{t('FilterBar.Somente itens permitidos')}
			</Typography>
			<OfferToolTip
				title={t('Classificações.Somente itens permitidos - informação')}
			>
				<Info
					style={{
						marginLeft: 8,
						fontSize: 16,
						cursor: 'pointer',
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorMedium
								: selectedTheme.primaryDark,
					}}
				/>
			</OfferToolTip>
		</Line>
	);

	const handleSetViewTagIDItems = (isChecked) => {
		setViewTagIDItems(isChecked);
		dispatch(
			updateClassificationsFilters({
				filterName: 'defaultFilters',
				filterData: {
					...filtersRedux.classifications.defaultFilters,
					viewTagIDItems: isChecked,
				},
			})
		);
		incrementNewKey();
	};

	const handleChangeViewTagID = () => {
		setCheckBoxStateTagID(!checkBoxStateTagID);
		handleSetViewTagIDItems(!checkBoxStateTagID);
	};

	const renderViewTagIDItems = () => (
		<Line>
			<Checkbox
				key={`checkTagIDItens-${viewTagIDItems}`}
				data-testid="viewTagIDItems"
				aria-label="tour"
				checked={checkBoxStateTagID}
				onChange={handleChangeViewTagID}
				color="primary"
				style={{
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.primaryDark,
				}}
			/>
			<Typography
				style={{
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.primary,
				}}
			>
				{t('FilterBar.Somente itens de buscas')}
			</Typography>
			<OfferToolTip
				title={t('Classificações.Somente itens de buscas - informação')}
			>
				<Info
					style={{
						marginLeft: 8,
						fontSize: 16,
						cursor: 'pointer',
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorMedium
								: selectedTheme.primaryDark,
					}}
				/>
			</OfferToolTip>
		</Line>
	);

	const handleChangeViewDenouncementItems = () => {
		setCheckBoxStateDenouncementItems(!checkBoxStateDenouncementItems);
		handleSetViewDenouncementItems(!checkBoxStateDenouncementItems);
	};

	const handleSetViewDenouncementItems = (isChecked) => {
		setViewDenouncementItems(isChecked);
		setViewDenouncements(isChecked ? selectedClient?.id : '');
		dispatch(
			updateClassificationsFilters({
				filterName: 'defaultFilters',
				filterData: {
					...filtersRedux.classifications.defaultFilters,
					viewDenouncements: selectedClient?.id,
					viewDenouncementItems: isChecked,
				},
			})
		);
		incrementNewKey();
	};

	const reactiveSearchHiddenComponents = () => (
		<DataSearch
			componentId="denouncements"
			value={viewDenouncements}
			dataField="denouncementClientID.keyword"
			size={1000000}
			showIcon={false}
			autosuggest={false}
			highlight={false}
			queryFormat="or"
			debounce={1}
			filterLabel={`${t('Classificações.Itens denunciados deste cliente')}`}
			style={{ display: 'none' }}
		/>
	);

	const renderViewDenouncementItems = () => (
		<Line
			style={{
				margin: '-5px 0 -5px',
			}}
		>
			<Checkbox
				key={`checkDenouncementItens-${viewDenouncementItems}`}
				aria-label="tour"
				data-testid="viewDenouncementItems"
				checked={checkBoxStateDenouncementItems}
				onClick={handleChangeViewDenouncementItems}
				color="primary"
				style={{
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.primaryDark,
				}}
			/>
			<Typography
				style={{
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.primary,
				}}
			>
				{t('Classificações.Itens denunciados deste cliente')}
			</Typography>
			<OfferToolTip
				title={t(
					'Classificações.Ao ativar este filtro, somente as ofertas denunciadas pelo cliente ativo serão exibidas'
				)}
			>
				<Info
					style={{
						marginLeft: 8,
						fontSize: 16,
						cursor: 'pointer',
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorMedium
								: selectedTheme.primaryDark,
					}}
				/>
			</OfferToolTip>
			{reactiveSearchHiddenComponents()}
		</Line>
	);

	return (
		<>
			<FormDivider
				name=""
				margin="8px 0"
				opacity={0.8}
				background={
					selectedTheme.id === 'dark' ? '#303030' : selectedTheme.background
				}
			/>
			<Column
				style={{
					rowGap: 5,
					paddingBlock: 5,
					margin: '-10px',
				}}
			>
				{checkboxAllowedItemsField && renderViewAllowedItems()}
				{checkboxTagIDItemsField && renderViewTagIDItems()}
				{checkboxDenouncementsItemsField && renderViewDenouncementItems()}
			</Column>
		</>
	);
};

export default ViewDenouncementItems;
