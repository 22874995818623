import React, { useEffect, useState } from 'react';
import { Column, Line } from '../../../../../styles';
import { usePO } from '../../../../../utils/POContext';

import {
	Box,
	Card,
	CardContent,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	MenuItem,
	TextField,
	Typography,
	Zoom,
} from '@mui/material';
import {
	Cancel,
	Check,
	CheckCircle,
	Close,
	InfoOutlined,
	NewReleases,
	VerifiedUser,
} from '@mui/icons-material';
import dateFormat from 'dateformat';
import LinkIcon from '@mui/icons-material/Link';
import { useTranslation } from 'react-i18next';
import FormDivider from '../../../../../components/FormDivider';
import {
	OfferToolTip,
	checkIsDate,
	convertDate,
	converteNomeParaSigla,
} from '../../../../../helpers';
import { getOfferClassificationByID } from '../../../../../services/classifications';
import ExpandableText from '../../../../../components/ExpandableText';

interface Props {
	rowData: any;
	item: any;
	denounceValue: any;
	setDenounceValue: (value) => void;
	denounceObs: any;
	setDenounceObs: (value) => void;
}

const NotificationModalRight: React.FC<Props> = ({
	rowData,
	item,
	denounceValue,
	setDenounceValue,
	setDenounceObs,
	denounceObs,
}) => {
	const { selectedTheme, selectedClient, classificationTypes, userData } =
		usePO();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const fetchOfferClassificationData = async () => {
		setLoading(true);
		const initialDenounceValue = classificationTypes.find(
			(obj) => obj.id === rowData?.classificationType.classificationTypeID
		);
		if (initialDenounceValue) {
			setDenounceValue(initialDenounceValue);
		} else {
			setDenounceValue('NOT_SET');
		}
		try {
			const response = await getOfferClassificationByID(
				selectedClient.id,
				item.offerID
			);
			if (response?.success) {
				const apiDenounceValue = classificationTypes.find(
					(obj) => obj.id === rowData?.classificationType.classificationTypeID
				);
				if (apiDenounceValue) {
					setDenounceValue(apiDenounceValue);
				} else {
					setDenounceValue('NOT_SET');
				}
			} else {
				setDenounceValue(initialDenounceValue || 'NOT_SET');
			}
		} catch (error) {
			console.error('Error fetching offer classification:', error);
			setDenounceValue(initialDenounceValue || 'NOT_SET');
		}

		setLoading(false);
	};

	useEffect(() => {
		if (item?.offerID) {
			fetchOfferClassificationData();
		} else {
			const initialDenounceValue = classificationTypes.find(
				(obj) => obj.id === rowData?.classificationType.classificationTypeID
			);
			setDenounceValue(initialDenounceValue || 'NOT_SET');
		}
	}, [item, rowData]);

	const handleDenounceReasonChange = (event: any) => {
		setDenounceValue(event.target.value);
	};

	const handleObservationChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setDenounceObs(event.target.value);
	};

	const renderBooleanValue = (value: boolean) =>
		value === true ? (
			<Check style={{ color: selectedTheme.success, fontSize: 14 }} />
		) : (
			<Close style={{ color: selectedTheme.error, fontSize: 14 }} />
		);

	const renderValueFormat = (infoType: string, value: any) => {
		if (value) {
			if (typeof value === 'boolean') {
				return renderBooleanValue(value);
			}
			if (
				infoType.toLowerCase().includes('valor') ||
				infoType.toLowerCase().includes('preço')
			) {
				return value.toLocaleString(userData.i18nID, {
					style: 'currency',
					currency: item?.offerData.currency.value,
					minimumFractionDigits: 2,
				});
			}
			if (checkIsDate(value)) {
				return convertDate(value, userData.i18nID, 'default');
			}
			return value;
		}
		return '-';
	};

	const renderOfficialStoreInfo = () => {
		return item?.author.authorData.official?.value ? (
			<Line style={{ justifyContent: 'flex-end' }}>
				<VerifiedUser
					style={{
						color: selectedTheme.success,
						fontSize: 16,
						marginRight: 5,
					}}
				/>
				<Typography
					variant="body2"
					align="right"
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Loja oficial')}
				</Typography>
			</Line>
		) : (
			<Line style={{ justifyContent: 'flex-end' }}>
				<NewReleases
					style={{
						color: selectedTheme.error,
						fontSize: 16,
						marginRight: 5,
					}}
				/>
				<Typography
					variant="body2"
					align="right"
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Loja não oficial')}
				</Typography>
			</Line>
		);
	};

	const renderVerifiedInfo = () => {
		return item?.author.authorData.verified?.value ? (
			<Line style={{ justifyContent: 'flex-start' }}>
				<CheckCircle
					style={{
						color: selectedTheme.success,
						fontSize: 15,
						marginRight: 5,
						marginTop: -2,
					}}
				/>
				<Typography
					variant="body2"
					align="left"
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Loja verificada')}
				</Typography>
			</Line>
		) : (
			<Line style={{ justifyContent: 'flex-start' }}>
				<Cancel
					style={{
						color: selectedTheme.error,
						fontSize: 15,
						marginRight: 5,
					}}
				/>
				<Typography
					variant="body2"
					align="left"
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Loja não verificada')}
				</Typography>
			</Line>
		);
	};

	const renderDenounceReason = () => (
		<>
			<Line
				style={{
					height: 35,
					background:
						denounceValue?.id === 'PSTLG'
							? selectedTheme.success
							: selectedTheme.warning,
					justifyContent: 'center',
				}}
			>
				<Typography
					align="center"
					noWrap
					variant="subtitle2"
					style={{
						color:
							denounceValue?.id !== 'PSTLG' && selectedTheme.id === 'dark'
								? selectedTheme.background
								: 'white',
						fontWeight: 'bold',
					}}
				>
					{denounceValue?.id !== 'PSTLG'
						? t('Notificações.Tabs.Notificadas.Motivo da Denúncia')
						: t('Classificações.Postagem marcada como permitida')}
				</Typography>
			</Line>
			<Column
				style={{
					justifyContent: 'space-between',
					height: '80%',
					alignItems: 'center',
					padding: 15,
				}}
			>
				<>
					<TextField
						variant="standard"
						key={denounceValue}
						select
						value={denounceValue}
						placeholder={t('Classificações.Escolha um motivo')}
						onChange={handleDenounceReasonChange}
						sx={{
							'& .MuiInputBase-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
							'& .MuiInputBase-root::before': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							'& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							cursor: 'pointer',
							width: '98%',
						}}
						InputProps={{
							endAdornment: denounceValue?.id !== 'NOT_SET' && (
								<InputAdornment
									position="end"
									style={{ marginRight: 30, cursor: 'pointer' }}
								>
									<OfferToolTip
										title={t(
											`ClassificationTypeDescription.${denounceValue?.description}`
										)}
										arrow
									>
										<InfoOutlined style={{ fontSize: 16 }} />
									</OfferToolTip>
								</InputAdornment>
							),
						}}
						SelectProps={{
							MenuProps: {
								sx: {
									'.MuiPaper-root': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									},
								},
							},
						}}
					>
						<MenuItem
							aria-label="none"
							value=""
							disabled
							style={{
								backgroundColor:
									selectedTheme.id === 'dark'
										? selectedTheme.overlay3dp
										: selectedTheme.foreground,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorDisable
										: '',
							}}
						/>
						{classificationTypes.map((item) => {
							if (item.ISactive) {
								return (
									<MenuItem
										value={item}
										key={item.name}
										sx={{
											cursor: 'pointer',
											background:
												selectedTheme.id === 'dark'
													? selectedTheme.overlay3dp
													: selectedTheme.foreground,
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
											'&:hover': {
												backgroundColor:
													selectedTheme.id === 'dark' &&
													selectedTheme.overlay6dp,
											},
										}}
									>
										{t(`ClassificationType.${item.id}`)}
									</MenuItem>
								);
							}
							return null;
						})}
					</TextField>
					<Line
						style={{ height: 60, justifyContent: 'center', padding: '10px' }}
					>
						<Typography
							variant="body2"
							style={{
								marginTop: 10,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: selectedTheme.textColorHigh,
							}}
						>
							{denounceValue?.description
								? t(
										`ClassificationTypeDescription.${denounceValue?.description}`
									)
								: ''}
						</Typography>
					</Line>
					<TextField
						size="small"
						id="denounce_obs"
						label={t('Classificações.Observações')}
						defaultValue={item?.denouncementObservation || denounceObs}
						onChange={handleObservationChange}
						multiline
						rows={6}
						variant="outlined"
						InputLabelProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						inputProps={{
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							width: '100%',
							marginTop: '35px',
						}}
					/>
				</>
			</Column>
		</>
	);

	const renderOfferDetails = () => (
		<Column style={{ alignItems: 'center', rowGap: 5 }}>
			<Line
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Column
					key="offerInfo"
					style={{
						height:
							'calc(100vh - 100px - 50px - 55px - 75px - 175px - 3 * 15px - 15px - 90px)', // 100vh - header - footer - renderOfferPriceInfo - renderPostInfo - renderDenounceReason - 3 * padding - author_Info_Inside_This_Card
						width: '100%',
					}}
				>
					<Grid container direction="column">
						{Object.entries(item?.author.authorData).map((data: any, index) => (
							<React.Fragment key={index}>
								<Line
									key={index}
									style={{
										width: '100%',
										justifyContent: 'space-between',
										marginBottom: 10,
										gap: 10,
									}}
								>
									<OfferToolTip
										arrow
										title={t('ClassificationDetails.' + data[1].key_name)}
									>
										<Typography
											variant="body2"
											style={{
												color:
													selectedTheme.id === 'dark' &&
													selectedTheme.textColorMedium,
												whiteSpace: 'nowrap',
											}}
										>
											<strong>
												{t('ClassificationDetails.' + data[1].key_name)}:
											</strong>
										</Typography>
									</OfferToolTip>
									<OfferToolTip arrow title={data[1].value}>
										<Typography
											variant="body2"
											style={{
												color:
													selectedTheme.id === 'dark' &&
													selectedTheme.textColorHigh,
											}}
										>
											{typeof data[1].value === 'boolean' ? (
												renderBooleanValue(data[1].value)
											) : (
												<ExpandableText
													text={
														typeof renderValueFormat(
															data[1].key_name,
															data[1].value
														) === 'string'
															? renderValueFormat(
																	data[1].key_name,
																	data[1].value
																)
															: String(
																	renderValueFormat(
																		data[1].key_name,
																		data[1].value
																	) || ''
																)
													}
													charLimit={40}
													maxLines={100}
												/>
											)}
										</Typography>
									</OfferToolTip>
								</Line>
								{index === 3 && (
									<Divider
										style={{
											width: '100%',
											background:
												selectedTheme.id === 'dark'
													? selectedTheme.footerLine
													: '',
											margin: '10px 0',
										}}
									/>
								)}
							</React.Fragment>
						))}
					</Grid>
				</Column>
			</Line>
		</Column>
	);

	const renderPostInfo = () => (
		<Column style={{ justifyContent: 'center', rowGap: 5 }}>
			<Line
				style={{
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Line
					style={{ width: '30%', justifyContent: 'flex-start', columnGap: 10 }}
				>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Notificações.Tabs.Classificadas.Fonte')}:
					</Typography>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{item?.sourceID}
					</Typography>
				</Line>
				<Line
					style={{
						width: '30%',
						justifyContent: 'flex-end',
						columnGap: 10,
						fontWeight: 'bold',
					}}
				>
					<Typography
						variant="body2"
						align="right"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Notificações.Tabs.Classificadas.Id do autor')}:
					</Typography>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{item?.authorID}
					</Typography>
				</Line>
			</Line>
			<Line
				style={{
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Line
					style={{
						width: '45%',
						justifyContent: 'flex-start',
						columnGap: 10,
					}}
				>
					<Typography
						variant="body2"
						align="right"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Notificações.Tabs.Classificadas.Atualizado em')}:
					</Typography>
					<Typography
						variant="body2"
						align="right"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{dateFormat(item?.updated, 'dd/mm/yyyy')}
					</Typography>
				</Line>
				<Line
					style={{
						width: '45%',
						justifyContent: 'flex-end',
						columnGap: 10,
					}}
				>
					<Typography
						variant="body2"
						align="right"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Notificações.Tabs.Classificadas.Id da oferta')}:
					</Typography>
					<Typography
						variant="body2"
						align="right"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{item?.offerID}
					</Typography>
				</Line>
			</Line>
			<FormDivider
				name={''}
				margin="8px 0px 10px 0px"
				width="90%"
				opacity={0.3}
				background={
					selectedTheme.id === 'dark' ? selectedTheme.primaryDark : 'white'
				}
			/>
			<Line
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Column
					style={{
						width: '48%',
						height:
							'calc(100vh - 100px - 50px - 55px - 75px - 175px - 3 * 15px - 15px - 90px)',
					}}
				>
					<Grid container direction="column" style={{ width: '96%' }}>
						<Line
							style={{
								width: '100%',
								justifyContent: 'space-between',
								marginBottom: 10,
							}}
						>
							<Line
								style={{
									justifyContent: 'flex-start',
									width: '100%',
								}}
							>
								<Typography
									variant="body2"
									noWrap
									style={{
										fontWeight: 'bold',
										width: '100%',
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									}}
								>
									{item.platformINcountryID.replace('|', ' - ')}
								</Typography>
							</Line>
						</Line>
						<Line
							style={{
								width: '100%',
								justifyContent: 'space-between',
								columnGap: 10,
								marginBottom: 10,
							}}
						>
							{item?.author.authorData.verified && renderVerifiedInfo()}
							{item.author.authorData.official ? (
								renderOfficialStoreInfo()
							) : (
								<Typography
									variant="body2"
									align="right"
									style={{
										fontWeight: 'bold',
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorHigh,
										whiteSpace: 'nowrap',
									}}
								>
									{t('Classificações.Status da loja não disponível')}
								</Typography>
							)}
						</Line>

						<Line
							style={{
								width: '100%',
								justifyContent: 'space-between',
								marginBottom: 10,
							}}
						>
							<Line
								style={{
									justifyContent: 'flex-start',
									width: '100%',
								}}
							>
								<Typography
									variant="body2"
									style={{
										fontWeight: 'bold',
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									}}
								>
									{t('Classificações.Autor')}:
								</Typography>
								{(item.author.permalink || rowData.author.permalink) && (
									<IconButton
										size="small"
										style={{
											fontSize: 14,
											transform: 'rotate(45deg)',
											padding: 5,
											marginBottom: -1,
											marginLeft: 5,
										}}
										sx={{
											'&:hover': {
												backgroundColor:
													selectedTheme.id === 'dark' &&
													selectedTheme.primaryLight,
											},
										}}
										href={item.author.permalink}
										target="blank"
									>
										<LinkIcon
											style={{
												fontSize: 15,
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorMedium
														: selectedTheme.primary,
											}}
										/>
									</IconButton>
								)}
								{(item.author.authorName || rowData.author.authorName) && (
									<OfferToolTip
										title={item.author.authorName || rowData.author.authorName}
										aria-label="inativo"
										enterDelay={700}
										enterNextDelay={700}
										TransitionComponent={Zoom}
										arrow
									>
										<Box
											maxWidth="220px"
											overflow="hidden"
											textOverflow="ellipsis"
											whiteSpace="nowrap"
											style={{ marginLeft: '5px' }}
										>
											<Link
												href={item.author.permalink || rowData.author.permalink}
												variant="body2"
												color="inherit"
												target="blank"
												sx={{ textDecoration: 'none' }}
											>
												<Typography
													variant="body2"
													noWrap
													style={{
														color:
															selectedTheme.id === 'dark' &&
															selectedTheme.textColorHigh,
													}}
												>
													{item.author.authorName || rowData.author.authorName}
												</Typography>
											</Link>
										</Box>
									</OfferToolTip>
								)}
							</Line>
							<Line style={{ justifyContent: 'flex-end', width: '33%' }}>
								{item.author.stateName ? (
									<OfferToolTip
										title={`${item.author.cityName}, ${item.author.stateName}`}
										aria-label="inativo"
										enterDelay={700}
										enterNextDelay={700}
										TransitionComponent={Zoom}
										arrow
									>
										<Box
											overflow="hidden"
											textOverflow="ellipsis"
											whiteSpace="nowrap"
										>
											<Line
												style={{
													justifyContent: 'flex-end',
													columnGap: 10,
												}}
											>
												<Typography
													variant="body2"
													noWrap
													style={{
														color:
															selectedTheme.id === 'dark' &&
															selectedTheme.textColorHigh,
													}}
												>
													{`${item.author.cityName},`}
												</Typography>
												<Typography
													variant="body2"
													noWrap
													style={{
														color:
															selectedTheme.id === 'dark' &&
															selectedTheme.textColorHigh,
													}}
												>
													{item.author?.stateName
														? converteNomeParaSigla(item.author.stateName)
														: 'n/d'}
												</Typography>
											</Line>
										</Box>
									</OfferToolTip>
								) : (
									<Typography
										variant="body2"
										align="right"
										noWrap
										style={{
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme.textColorHigh,
										}}
									>
										{item.author.cityName !== null
											? `${item.author.cityName}`
											: t('Classificações.Localidade não disponível')}
									</Typography>
								)}
							</Line>
						</Line>
						<FormDivider
							name={''}
							margin="11px 0px"
							width="100%"
							opacity={0.3}
							background={
								selectedTheme.id === 'dark'
									? selectedTheme.primaryDark
									: 'white'
							}
						/>
						{Object.entries(item?.offerData).map((data: any, index) => (
							<React.Fragment key={index}>
								<Line
									style={{
										width: '100%',
										justifyContent: 'space-between',
										marginBottom: 10,
										gap: 10,
									}}
								>
									<OfferToolTip
										arrow
										title={t('ClassificationDetails.' + data[1].key_name)}
									>
										<Typography
											variant="body2"
											style={{
												color:
													selectedTheme.id === 'dark' &&
													selectedTheme.textColorMedium,
												whiteSpace: 'nowrap',
											}}
										>
											<strong>
												{t('ClassificationDetails.' + data[1].key_name)}:
											</strong>
										</Typography>
									</OfferToolTip>
									<OfferToolTip arrow title={data[1].value}>
										<Typography
											variant="body2"
											style={{
												color:
													selectedTheme.id === 'dark' &&
													selectedTheme.textColorHigh,
											}}
										>
											{typeof data[1].value === 'boolean' ? (
												renderBooleanValue(data[1].value)
											) : (
												<ExpandableText
													text={
														typeof renderValueFormat(
															data[1].key_name,
															data[1].value
														) === 'string'
															? renderValueFormat(
																	data[1].key_name,
																	data[1].value
																)
															: String(
																	renderValueFormat(
																		data[1].key_name,
																		data[1].value
																	) || ''
																)
													}
													charLimit={40}
													maxLines={100}
												/>
											)}
										</Typography>
									</OfferToolTip>
								</Line>

								{index === 3 && (
									<Divider
										style={{
											width: '100%',
											background:
												selectedTheme.id === 'dark'
													? selectedTheme.footerLine
													: '',
											margin: '10px 0',
										}}
									/>
								)}
							</React.Fragment>
						))}
					</Grid>
				</Column>
				<Column
					style={{
						width: '48%',
						height:
							'calc(100vh - 100px - 50px - 55px - 75px - 175px - 45px - 15px - 90px)',
					}}
				>
					{renderOfferDetails()}
				</Column>
			</Line>
		</Column>
	);

	const fixedTop = () => (
		<Line
			style={{
				height: '100%',
				justifyContent: 'space-between',
			}}
		>
			<Line>
				{item?.permalink ? (
					<>
						<Typography
							variant="body2"
							align="right"
							noWrap
							style={{
								fontWeight: 'bold',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{t('Notificações.Tabs.Classificadas.Link')}:
						</Typography>
						<IconButton
							size="small"
							style={{
								fontSize: 14,
								transform: 'rotate(45deg)',
								padding: 5,
								marginBottom: -1,
								marginLeft: 5,
							}}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
							href={item?.permalink}
							target="blank"
						>
							<LinkIcon
								style={{
									fontSize: 15,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorMedium
											: selectedTheme.primary,
								}}
							/>
						</IconButton>
						<OfferToolTip
							title={item?.permalink}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							TransitionComponent={Zoom}
							arrow
						>
							<Box
								maxWidth="220px"
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								style={{ marginLeft: '5px' }}
							>
								<Link
									href={item?.permalink}
									variant="body2"
									color="inherit"
									target="blank"
									sx={{ textDecoration: 'none' }}
								>
									<Typography
										variant="body2"
										noWrap
										style={{
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme.textColorHigh,
										}}
									>
										{item?.permalink}
									</Typography>
								</Link>
							</Box>
						</OfferToolTip>
					</>
				) : null}
			</Line>

			<Line style={{ columnGap: 10, justifyContent: 'flex-end' }}>
				<Typography
					variant="body2"
					noWrap
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					}}
				>
					{t('Notificações.Tabs.Classificadas.Categoria')}:
				</Typography>
				<OfferToolTip
					title={item?.offerCategory || null}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					TransitionComponent={Zoom}
					arrow
				>
					<Box
						maxWidth="220px"
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
					>
						<Typography
							noWrap
							variant="body2"
							style={{
								marginBottom: -1,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{item?.offerCategory || null}
						</Typography>
					</Box>
				</OfferToolTip>
			</Line>
		</Line>
	);

	return (
		<Grid
			item
			xs={7}
			style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
		>
			{/* cardDir01 */}
			<Card
				sx={{
					height: '55px',
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
				}}
			>
				<CardContent style={{ padding: '15px 20px' }}>{fixedTop()}</CardContent>
			</Card>

			{/* cardDir02 */}
			<Card
				sx={{
					flexGrow: 1,
					flexShrink: 1,
					flexBasis: '32vh',
					transition: 'height 0.4s ease-in-out',
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
					padding: '0px 0px 15px',
				}}
			>
				<CardContent
					style={{ overflowY: 'auto', maxHeight: '100%', overflowX: 'hidden' }}
				>
					{renderPostInfo()}
				</CardContent>
			</Card>

			{/* cardDir03 */}
			<Card
				sx={{
					flexGrow: 2,
					flexShrink: 1,
					flexBasis: '35vh',
					padding: '0px',
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
				}}
			>
				<CardContent style={{ padding: 0, overflowY: 'auto' }}>
					{loading ? (
						<CircularProgress
							size={25}
							style={{ position: 'relative', top: '50%', left: '50%' }}
						/>
					) : (
						renderDenounceReason()
					)}
				</CardContent>
			</Card>
		</Grid>
	);
};
export default NotificationModalRight;
