import React, { CSSProperties } from 'react';

interface SvgIconProps {
	SvgComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	fillColor?: string;
	style?: CSSProperties;
}

const SvgColorManipulate: React.FC<SvgIconProps> = ({
	SvgComponent,
	fillColor = '#000000',
	style,
}) => {
	return (
		<>
			<SvgComponent className="svg-icon" style={style} />
			<style>
				{`
          			.svg-icon * {
          			  fill: ${fillColor} !important; 
           			 ${fillColor ? `stroke: ${fillColor} !important;` : ''}
         			 }
       			 `}
			</style>
		</>
	);
};

export default SvgColorManipulate;
