import React, { useEffect, useState } from 'react';
import { api, isAxiosError } from '../../../utils/api';
import { IconButton, Typography, Zoom } from '@mui/material';
import { OfferToolTip } from '../../../helpers';
import { Refresh } from '@mui/icons-material/';
import { usePO } from '../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import BuildPlatform from './BuildPlatform';
import DeleteINCountryDialog from './DeleteINCountryDialog';
import DesactivatePlatformDialog from './DesactivatePlatformDialog';
import EditPlatformModal from './EditPlatformModal';
import OfferTable from '../../../components/OfferTable';
import { Line } from '../../../styles';
import PlatformIcon from '../../../components/PlatformIcon';
import { ErrorResponse, PlatformOptionsType } from '../../../interfaces';

const Plataformas: React.FC = () => {
	const { userData, updateCursorMode, selectedTheme } = usePO();
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [reload, setReload] = useState(false);

	const [receivedDataSwitch, setReceivedDataSwitch] = useState<any>();

	const [tableData, setTableData] = useState<any[]>([]);
	const [availablePlatforms, setAvailablePlatforms] = useState<any[]>();
	const [platformsINcountries, setPlatformsINcountries] = useState<any[]>();
	const [optionsPlatformType, setOptionsPlatformType] = useState<
		PlatformOptionsType[]
	>([]);

	const switchesToolTip = {
		true: t('Plataforma ativada'),
		false: t('Plataforma desativada'),
	};

	const platformTypeWritten: { [key: string]: string } = {
		marketplace: 'Marketplace',
		'social-media': 'Social Media',
	};

	const colsData = [
		{
			columnTitle: '',
			fieldName: 'id',
			translateColumnData: false,
			cellWidth: 30,
			align: 'center',
			dataType: 'reactObject',
		},

		{
			columnTitle: t('Buscas.Tabs.Plataformas.Plataforma'),
			fieldName: 'name',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 20px',
			cellWidth: 50,
		},
		{
			columnTitle: t('Buscas.Tabs.Plataformas.Tipo da plataforma'),
			fieldName: 'platformType',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 10px',
			cellWidth: 50,
		},
		{
			columnTitle: t('Buscas.Tabs.Plataformas.País'),
			fieldName: 'country',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 100,
		},
		{
			columnTitle: t('Buscas.Tabs.Plataformas.Descrição'),
			fieldName: 'description',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 30px',
			cellWidth: 200,
		},
		{
			columnTitle: t('Buscas.Tabs.Plataformas.Rotas'),
			fieldName: 'routes',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 30px',
			cellWidth: 150,
		},
		{
			columnTitle: t('Buscas.Tabs.Plataformas.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 30px',
			cellWidth: 50,
			dataType: 'date',
		},
		{
			columnTitle: t('Buscas.Tabs.Plataformas.Recursos'),
			fieldName: 'features',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 50,
			dataType: 'reactObject',
		},
	];

	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const countries = require('i18n-iso-countries');
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/pt.json')
	);
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/en.json')
	);
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/es.json')
	);

	const renderStyledResources = (resources: boolean) => {
		if (resources) {
			return selectedTheme.id === 'dark' ? selectedTheme.warning : 'darkorange';
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorDisable
				: selectedTheme.disabled;
		}
	};

	const renderPlatformLogo = (id: string) => (
		<PlatformIcon data={id} dataType="platform" size={24} cursor="default" />
	);

	const renderPlatformFeatures = (hasParser: boolean, hasCrawler: boolean) => (
		<Line style={{ justifyContent: 'center', width: '100%', columnGap: 10 }}>
			<OfferToolTip
				title={
					hasCrawler
						? `${t(
								'Buscas.Tabs.Plataformas.Plataforma possui um crawler ativo'
							)}`
						: `${t(
								'Buscas.Tabs.Plataformas.Plataforma não possui um crawler ativo'
							)}`
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Typography
					variant="h6"
					align="center"
					style={{
						fontWeight: 'bold',
						color: renderStyledResources(hasCrawler),
					}}
				>
					C
				</Typography>
			</OfferToolTip>
			<OfferToolTip
				title={
					hasParser
						? `${t(
								'Buscas.Tabs.Plataformas.Plataforma possui um parser ativo'
							)}`
						: `${t(
								'Buscas.Tabs.Plataformas.Plataforma não possui um parser ativo'
							)}`
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Typography
					variant="h6"
					align="center"
					style={{
						fontWeight: 'bold',
						color: renderStyledResources(hasParser),
					}}
				>
					P
				</Typography>
			</OfferToolTip>
		</Line>
	);

	const replaceRoutesValue = (routes: string[] | null): string[] => {
		if (!routes || routes.length === 0) {
			return ['-'];
		}
		return routes.map((route) => {
			if (route === '*') {
				return '[...]';
			}
			return route
				.replace(/([\s\S]*)\/video/, '[...]/video')
				.replace(/marketplace\/([\s\S]*)/, 'marketplace/[...]')
				.replace(/([\s\S]*)\/photos/, '[...]/photos')
				.replace(/groups\/([\s\S]*)\/posts/, 'groups/[...]/posts')
				.replace(/([\s\S]*)\/videos/, '[...]/videos')
				.replace(/([\s\S]*)\/dp/, '[...]/dp');
		});
	};

	const buildTableData = () => {
		if (availablePlatforms && platformsINcountries) {
			const newData = availablePlatforms.reduce((acc, platform) => {
				const matchedInCountries = platformsINcountries.filter(
					(inCountry) => platform.id === inCountry.platformID
				);
				const platformsData = matchedInCountries.map((inCountry) => ({
					name: platform.name,
					id: renderPlatformLogo(platform.id),
					country: countries.getName(
						inCountry.countryID,
						userData.i18nID.substring(0, 2),
						{ select: 'official' }
					),
					platformType:
						platformTypeWritten[platform.platformType] ||
						platform.platformTypeID,
					countryISO: inCountry.countryID,
					features: renderPlatformFeatures(
						inCountry.hasParser,
						inCountry.hasCrawler
					),
					description: t(`PlatformDescription.${platform.description}`),
					platformID: platform.id,
					inCountryID: inCountry.id,
					created: inCountry.created,
					routes: replaceRoutesValue(inCountry.routes),
					ISactive: inCountry.ISactive,
					logo: platform.logo,
				}));
				return acc.concat(platformsData);
			}, []);
			setTableData(newData);
		} else {
			console.log('Dados não disponíveis para construir a tabela.');
		}
	};

	if (tableData.length === 0) {
		buildTableData();
	}

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line
	}, [reload]);

	const fetchData = async () => {
		setLoading(true);
		setTableData([]);
		updateCursorMode('wait');
		try {
			const platformsData = await api.get('/platforms');
			setAvailablePlatforms(platformsData.data);
			const platformsINcountryData = await api.get('/platformINcountry');
			setPlatformsINcountries(platformsINcountryData.data);
			const response = await api.get('/platformType');
			setOptionsPlatformType(response.data);
			buildTableData();
			updateCursorMode('default');
			setLoading(false);
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					return {
						success: false,
						status: errorResponse.status,
						message: errorResponse.data.detail,
					};
				} else {
					return {
						success: false,
						status: 500,
						message:
							'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
					};
				}
			}
		}
	};

	const reFetchData = async () => {
		setReload(!reload);
	};

	const ActionComponent1Props = {
		refresh: () => reFetchData(),
		platformTypeOptions: optionsPlatformType,
	};

	const ActionComponent2Props = {
		refresh: () => reFetchData(),
	};

	const createNewPlatform = () => (
		<BuildPlatform
			refresh={() => reFetchData()}
			availablePlatformsData={availablePlatforms}
			platformTypeOptions={optionsPlatformType}
		/>
	);

	const renderReloadTableData = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Plataformas.Atualizar dados da tabela')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={reFetchData}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Refresh
					style={{
						fontSize: 23,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	return (
		<>
			<OfferTable
				tableData={tableData}
				colsData={colsData}
				loading={loading}
				tableActions
				tableFooter
				tableFooterButton={
					userData.roles?.indexOf('Desenvolvedor') !== -1
						? createNewPlatform()
						: null
				}
				customNoDataMessage={
					!loading &&
					tableData.length == 0 &&
					t('Buscas.Tabs.Plataformas.Sem plataformas')
				}
				customLeftFooterComponent={renderReloadTableData()}
				dataFromSwitch={setReceivedDataSwitch}
				ActionComponent1={
					userData.roles?.indexOf('Desenvolvedor') !== -1
						? EditPlatformModal
						: null
				}
				ActionComponent1Props={ActionComponent1Props}
				ActionComponent2={
					userData.roles?.indexOf('Desenvolvedor') !== -1
						? DeleteINCountryDialog
						: null
				}
				ActionComponent2Props={ActionComponent2Props}
				switches
				switchesToolTip={switchesToolTip}
				onSwitchChange={() => setOpen(true)}
				userPermission={
					userData.roles?.indexOf('Desenvolvedor') !== -1 ? false : true
				}
			/>
			<DesactivatePlatformDialog
				open={open}
				setOpen={setOpen}
				platformData={receivedDataSwitch}
				refresh={() => reFetchData()}
			/>
		</>
	);
};

export default Plataformas;
