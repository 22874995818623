import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, List, ListItem, Zoom } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material/';
import { ReactComponent as OfferSlogan } from '../../../assets/images/svg/OfferSlogan.svg';
import { usePO } from '../../../utils/POContext';
import { Line } from '../../../styles';
import { HandleGetThemeColor, OfferToolTip } from '../../../helpers';
import SvgColorManipulate from '../../SvgColorManipulate';

interface Props {
	openMenu: boolean;
	denseMenu: boolean;
	transition: boolean;
	handleOpenDrawer: () => void;
}

const TopMenuIcons: React.FC<Props> = ({
	openMenu,
	denseMenu,
	handleOpenDrawer,
	transition,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const handleChangedTheme = () => {
		if (selectedTheme.id === 'main') {
			return selectedTheme.primary;
		} else if (selectedTheme.id === 'dark') {
			return selectedTheme.textColorHigh;
		} else {
			return 'white';
		}
	};

	const renderOpenMenuButton = () => {
		if (openMenu) {
			return (
				<ChevronLeft
					style={{
						fontSize: denseMenu ? 28 : 32,
						color: handleChangedTheme(),
					}}
				/>
			);
		} else {
			return (
				<OfferToolTip
					placement="right"
					title={`${t('VertMenu.Expandir Menu')}`}
					aria-label="Expandir Menu"
					arrow
					enterDelay={200}
					enterNextDelay={200}
					TransitionComponent={Zoom}
				>
					<ChevronRight
						style={{
							fontSize: denseMenu ? 28 : 32,
							marginRight: -3,
							color: handleChangedTheme(),
						}}
					/>
				</OfferToolTip>
			);
		}
	};

	return (
		<div
			style={{
				height: 70,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
				marginRight: 12,
				gap: 6,
				paddingTop: denseMenu ? 0 : 15,
			}}
		>
			<List>
				<ListItem>
					{(openMenu || transition) && (
						<Line
							style={{
								marginRight: denseMenu ? -20 : -23,
								transition: 'opacity 0.5s ease-in-out',
								opacity: openMenu ? 1 : 0,
							}}
						>
							<IconButton
								href="https://www.offertech.com.br"
								target="/blank"
								disabled={!openMenu}
								style={{ borderRadius: 10 }}
								sx={{
									padding: '15px 6px',
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.primaryLight,
									},
								}}
							>
								<SvgColorManipulate
									key={`${openMenu}|${selectedTheme.id}`}
									SvgComponent={OfferSlogan}
									style={denseMenu ? { width: 95 } : { width: 120 }}
									fillColor={HandleGetThemeColor({
										themeID: 'dark',
										primaryThemeColor: selectedTheme.textColorHigh,
										alternateThemeColor:
											selectedTheme.id === 'main'
												? selectedTheme.primary
												: '#fff',
									})}
								/>
							</IconButton>
						</Line>
					)}
				</ListItem>
			</List>
			<IconButton
				size={openMenu ? 'small' : 'medium'}
				sx={{
					marginRight: denseMenu ? '-2px' : '0px',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
				onClick={handleOpenDrawer}
			>
				{renderOpenMenuButton()}
			</IconButton>
		</div>
	);
};

export default TopMenuIcons;
