import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {
	IconButton,
	TextField,
	Typography,
	Zoom,
	Autocomplete,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Line } from '../../../../styles';
import { OfferToolTip } from '../../../../helpers';
import { AddCircleRounded, RemoveCircleRounded } from '@mui/icons-material/';
import { usePO } from '../../../../utils/POContext';
import { makeStyles } from '@mui/styles';

const getOrLogicState = (rule, listOfRules) => {
	// get the last concatOperator of listOfRules for set inside the button
	if (rule.concatOperator === '') {
		if (listOfRules[listOfRules?.length - 2]?.concatOperator === '|') {
			return false;
		} else {
			return true;
		}
	}
	if (rule.concatOperator === '|') {
		return true;
	} else {
		return false;
	}
};

const RulesFields = {
	Operators: [
		'contain',
		'not contain',
		'equal',
		'greater than',
		'less than',
		'greater than or equal',
		'less than or equal',
	],
	Fields: [
		'offer_platformINcountryID',
		'offer_title',
		'offer_description',
		'offerData_anatel_number',
		'offerData_price',
		'author_cityName',
		'author_stateName',
		'author_countryName',
		'author_authorName',
		'author_authorID',
		'offerData_items_sold',
	],
};

const FieldToOperators = {
	offer_platformINcountryID: ['contain'],
	offer_title: ['contain', 'not contain'],
	offer_description: ['contain', 'not contain'],
	offerData_anatel_number: [
		'equal',
		'greater than',
		'less than',
		'greater than or equal',
		'less than or equal',
	],
	offerData_price: [
		'equal',
		'greater than',
		'less than',
		'greater than or equal',
		'less than or equal',
	],
	offerData_items_sold: [
		'equal',
		'greater than',
		'less than',
		'greater than or equal',
		'less than or equal',
	],
	author_cityName: ['contain', 'not contain'],
	author_stateName: ['contain', 'not contain'],
	author_countryName: ['contain', 'not contain'],
	author_authorName: ['contain', 'not contain'],
	author_authorID: ['contain', 'not contain'],
};

interface Props {
	rule: any;
	index: any;
	handleUpdateRuleField: (index, field, value) => void;
	handleAddRuleFields: () => void;
	handleRemoveRuleFields: (index) => void;
	ruleEmpyt: boolean;
	listOfRules: any;
}
const RuleFields: React.FC<Props> = ({
	rule,
	index,
	handleUpdateRuleField,
	handleAddRuleFields,
	handleRemoveRuleFields,
	ruleEmpyt,
	listOfRules,
}) => {
	const { t } = useTranslation();
	const { selectedTheme, userData } = usePO();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [allowedOperators, setAllowedOperators] = useState(
		RulesFields.Operators
	);
	const [operatorsType, setOperatorsType] = useState<string>('');
	const [orLogic, setOrLogic] = useState(getOrLogicState(rule, listOfRules));

	useEffect(() => {
		setAllowedOperators(
			FieldToOperators[rule?.fields] || RulesFields.Operators
		);
	}, [rule?.fields]);

	const updateRuleField = (field, value) => {
		switch (field) {
			case 'fields':
				handleUpdateRuleField(index, 'fields', value);
				setOperatorsType(value);
				break;
			case 'operators':
				handleUpdateRuleField(index, 'operators', value);
				break;
			case 'value':
				handleUpdateRuleField(index, 'value', value);
				break;
			case 'concatOperator':
				handleUpdateRuleField(index - 1, 'concatOperator', value);
				break;
		}
	};

	const textIconStyleHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.primary;
		}
	};

	const useStyles = makeStyles(() => ({
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		autoCompleteStyle: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	}));

	const classes = useStyles();

	const handleChangeInput = () => {
		let prefix = '';
		if (operatorsType == 'offerData_price') {
			prefix = userData.i18nID === 'pt-br' ? 'R$ ' : '$ ';
		}

		if (
			operatorsType == 'offerData_price' ||
			operatorsType == 'offerData_items_sold' ||
			operatorsType == 'offerData_anatel_number'
		) {
			return (
				<NumericFormat
					name="value"
					value={rule?.value}
					customInput={TextField}
					error={ruleEmpyt && !rule?.value}
					size="small"
					prefix={prefix}
					variant="outlined"
					decimalSeparator=","
					decimalScale={2}
					placeholder={t('Regras.Valor')}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(event) => {
						handleValidateValue(rule?.operators, event.target.value);
					}}
					inputProps={{
						inputComponent: NumericFormat as any,
						style: {
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						},
					}}
					sx={{
						width: '30%',
						marginLeft: '10px',
						marginRight: '10px',
						cursor: 'pointer',
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
					}}
				/>
			);
		} else {
			return (
				<TextField
					name="value"
					value={rule?.value}
					error={ruleEmpyt && !rule?.value}
					variant="outlined"
					size="small"
					placeholder={t('Regras.Valor')}
					InputLabelProps={{ shrink: true }}
					onChange={(event) => {
						handleValidateValue(rule?.operators, event.target.value);
					}}
					inputProps={{
						inputComponent: NumericFormat as any,
						style: {
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						},
					}}
					sx={{
						width: '30%',
						marginLeft: '10px',
						marginRight: '10px',
						cursor: 'pointer',
						'& .MuiInputBase-root': {
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
					}}
				/>
			);
		}
	};

	const handleValidateValue = (operators, value) => {
		const prefix = userData.i18nID === 'pt-br' ? 'R$ ' : '$ ';
		if (value.startsWith(prefix)) {
			value = value.substring(prefix.length);
		}
		value = value.replace(/\\$/g, '');
		updateRuleField('value', value);
	};

	const handleChangeLogic = () => {
		setOrLogic(!orLogic);
		handleUpdateRuleField(index - 1, 'concatOperator', !orLogic ? '&' : '|');
	};

	const renderRuleFields = () => (
		<Line>
			{index !== 0 ? (
				<IconButton
					color="primary"
					size="small"
					onClick={handleChangeLogic}
					sx={{
						height: '24px',
						width: '31px',
						borderRadius: '4px',
						border: `1px solid ${selectedTheme.id === 'dark' ? '#575757' : selectedTheme.primary}`,
						marginRight: '10px',
						background: selectedTheme.id === 'dark' && selectedTheme.overlay3dp,
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<OfferToolTip
						title={t('Regras.Alternar entre logica E e OU')}
						aria-label="inativo"
						enterDelay={700}
						enterNextDelay={700}
						arrow
						TransitionComponent={Zoom}
					>
						<Typography
							variant="caption"
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: selectedTheme.primaryDark,
								marginBottom: -1,
							}}
						>
							{orLogic ? 'E' : 'OU'}
						</Typography>
					</OfferToolTip>
				</IconButton>
			) : null}
			<Autocomplete
				data-testid="selectedFieldInput"
				id="field-select"
				style={{ width: index !== 0 ? '27%' : '31.5%', cursor: 'pointer' }}
				options={RulesFields.Fields}
				value={rule?.fields}
				onChange={(_, newValue: any) => {
					updateRuleField('fields', newValue);
					setAllowedOperators(
						FieldToOperators[newValue] || RulesFields.Operators
					);
					updateRuleField('operators', null);
				}}
				autoHighlight
				autoComplete
				autoSelect
				disableClearable
				loadingText={t('Carregando...')}
				selectOnFocus
				getOptionLabel={(option) =>
					option ? t(`Regras.Campos.${option}`) : ''
				}
				renderOption={(props, option) => (
					<li {...props}>{t(`Regras.Campos.${option}`)}</li>
				)}
				classes={{
					option: classes.option,
					root: classes.autoCompleteStyle,
					paper: classes.autoCompleteStyle,
				}}
				sx={{
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
					'.MuiSvgIcon-root ': {
						fill:
							selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
					},
					background:
						selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						variant={'outlined'}
						name="selectedField"
						data-testid="selectedFieldInput"
						size="small"
						placeholder={t('Regras.Campo')}
						error={ruleEmpyt && !rule?.fields}
						inputProps={{
							...params.inputProps,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							},
						}}
						sx={{
							width: '100%',
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
					/>
				)}
			/>

			<Autocomplete
				data-testid="selectedOperatorInput"
				id="operator-select"
				style={{ width: '30%', marginLeft: 10, cursor: 'pointer' }}
				options={RulesFields.Operators as string[]}
				value={rule?.operators}
				onChange={(_, newValue: any) => {
					updateRuleField('operators', newValue);
				}}
				getOptionDisabled={(option) => !allowedOperators.includes(option)}
				autoHighlight
				autoComplete
				autoSelect
				disableClearable
				loadingText={t('Carregando...')}
				selectOnFocus
				getOptionLabel={(option) =>
					option ? t(`Regras.Operadores.${option}`) : ''
				}
				renderOption={(props, option) => (
					<li {...props}>{t(`Regras.Operadores.${option}`)}</li>
				)}
				classes={{
					option: classes.option,
					root: classes.autoCompleteStyle,
					paper: classes.autoCompleteStyle,
				}}
				sx={{
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
					'& .MuiInputBase-root': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
					'.MuiSvgIcon-root ': {
						fill:
							selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
					},
					background:
						selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						variant={'outlined'}
						name="selectedOperator"
						data-testid="selectedOperatorInput"
						size="small"
						error={ruleEmpyt && !rule?.operators}
						placeholder={t('Regras.Operador')}
						inputProps={{
							...params.inputProps,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							},
						}}
						sx={{
							width: '100%',
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						}}
					/>
				)}
			/>

			{handleChangeInput()}

			<IconButton
				sx={{
					marginRight: '-15px',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				{index === 0 ? (
					<OfferToolTip
						title={t('Regras.Adicionar novo conjunto de regra')}
						aria-label="inativo"
						enterDelay={700}
						enterNextDelay={700}
						arrow
						TransitionComponent={Zoom}
					>
						<AddCircleRounded
							style={{
								color: textIconStyleHeader(),
							}}
							onClick={handleAddRuleFields}
						/>
					</OfferToolTip>
				) : (
					<OfferToolTip
						title={t('Regras.Remover conjunto de regra')}
						aria-label="inativo"
						enterDelay={700}
						enterNextDelay={700}
						arrow
						TransitionComponent={Zoom}
					>
						<RemoveCircleRounded
							style={{
								color: textIconStyleHeader(),
							}}
							onClick={() => handleRemoveRuleFields(index)}
						/>
					</OfferToolTip>
				)}
			</IconButton>
		</Line>
	);

	return renderRuleFields();
};

export default RuleFields;
