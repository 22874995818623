import React, { useEffect, useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	MenuItem,
	TextField,
	Typography,
	Zoom,
} from '@mui/material';
import {
	Cancel,
	Check,
	CheckCircle,
	Close,
	InfoOutlined,
	NewReleases,
	VerifiedUser,
} from '@mui/icons-material/';
import LinkIcon from '@mui/icons-material/Link';
import FormDivider from '../../../../components/FormDivider';
import {
	OfferToolTip,
	checkIsDate,
	convertDate,
	converteNomeParaSigla,
} from '../../../../helpers';
import { Line, Column } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { getOfferClassificationByID } from '../../../../services/classifications';
import ExpandableText from '../../../../components/ExpandableText';

/* 
  🚨 Maintenance Note 🚨
	Please be cautious when modifying the height calculations on the main return 
  on this component. They are calculated dynamically based on viewport and other
  elements' heights.	Make sure to thoroughly test any changes on different screen sizes. 
*/

interface Props {
	offerData: any;
	setEnableClassification: (isEnabled: boolean) => void;
	enableClassification: boolean;
	denounceValue: any;
	setDenounceValue: (value) => void;
	denounceObs: string;
	setDenounceObs: (value) => void;
}

const RightColumn: React.FC<Props> = ({
	offerData,
	setEnableClassification,
	enableClassification,
	denounceValue,
	setDenounceValue,
	denounceObs,
	setDenounceObs,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme, selectedClient, classificationTypes, userData } =
		usePO();

	const [loading, setLoading] = useState(false);

	const fetchOfferClassificationData = async () => {
		setLoading(true);
		const response = await getOfferClassificationByID(
			selectedClient.id,
			offerData.offerID
		);
		if (response?.success === true) {
			setEnableClassification(false);
			setDenounceValue(
				classificationTypes.find(
					(obj) => obj.id === response.data.classificationTypeID
				)
			);
			setDenounceObs(response.data.observation);
		}
		if (response?.success === false) {
			setEnableClassification(true);
			setDenounceValue({ id: 'NOT_SET' });
			setDenounceObs('');
		}
		setLoading(false);
	};

	useEffect(() => {
		if (offerData?.offerID) {
			fetchOfferClassificationData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offerData]);

	const handleDenounceReasonChange = (event: any) => {
		setDenounceValue(event.target.value);
	};

	const handleObservationChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setDenounceObs(event.target.value);
	};

	const renderBooleanValue = (value: boolean) =>
		value === true ? (
			<Check style={{ color: selectedTheme.success, fontSize: 14 }} />
		) : (
			<Close style={{ color: selectedTheme.error, fontSize: 14 }} />
		);

	const renderValueFormat = (infoType: string, value: any) => {
		if (value) {
			if (typeof value === 'boolean') {
				return renderBooleanValue(value);
			}
			if (
				infoType.toLowerCase().includes('valor') ||
				infoType.toLowerCase().includes('preço')
			) {
				return value.toLocaleString(userData.i18nID, {
					style: 'currency',
					currency: offerData.offerData.currency.value,
					minimumFractionDigits: 2,
				});
			}
			if (checkIsDate(value)) {
				return convertDate(value, userData.i18nID, 'default');
			}
			return value;
		}
		return '-';
	};

	const renderDenounceReason = () => (
		<>
			<Line
				style={{
					height: 35,
					background:
						denounceValue.id === 'PSTLG'
							? selectedTheme.success
							: selectedTheme.warning,
					justifyContent: 'center',
				}}
			>
				<Typography
					align="center"
					noWrap
					variant="subtitle2"
					style={{
						color:
							denounceValue.id !== 'PSTLG' && selectedTheme.id === 'dark'
								? selectedTheme.background
								: 'white',
						fontWeight: 'bold',
					}}
				>
					{denounceValue.id !== 'PSTLG'
						? t('Classificações.Motivo da Classificação')
						: t('Classificações.Postagem marcada como permitida')}
				</Typography>
			</Line>
			<Column
				style={{
					justifyContent: 'space-between',
					height: '80%',
					alignItems: 'center',
					padding: 15,
				}}
			>
				<>
					<TextField
						variant="standard"
						select
						value={denounceValue}
						placeholder={t('Classificações.Escolha um motivo')}
						onChange={
							enableClassification ? handleDenounceReasonChange : undefined
						}
						sx={{
							'& .MuiInputBase-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
							'& .MuiInputBase-root::before': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							'& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							cursor: 'pointer',
							width: '98%',
						}}
						InputProps={{
							endAdornment: denounceValue.id !== 'NOT_SET' && (
								<InputAdornment
									position="end"
									style={{ marginRight: 30, cursor: 'pointer' }}
								>
									<OfferToolTip
										title={t(
											`ClassificationTypeDescription.${denounceValue.description}`
										)}
										arrow
									>
										<InfoOutlined style={{ fontSize: 16 }} />
									</OfferToolTip>
								</InputAdornment>
							),
						}}
						SelectProps={{
							MenuProps: {
								sx: {
									'.MuiPaper-root': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									},
								},
							},
						}}
					>
						<MenuItem
							aria-label="none"
							value=""
							disabled
							style={{
								backgroundColor:
									selectedTheme.id === 'dark'
										? selectedTheme.overlay3dp
										: selectedTheme.foreground,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorDisable
										: '',
							}}
						/>
						{classificationTypes.map((item) => {
							if (item.ISactive) {
								return (
									<MenuItem
										value={item}
										key={item.name}
										sx={{
											cursor: 'pointer',
											background:
												selectedTheme.id === 'dark'
													? selectedTheme.overlay3dp
													: selectedTheme.foreground,
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
											'&:hover': {
												backgroundColor:
													selectedTheme.id === 'dark' &&
													selectedTheme.overlay6dp,
											},
										}}
									>
										{t(`ClassificationType.${item.id}`)}
									</MenuItem>
								);
							}
							return null;
						})}
					</TextField>
					<Line
						style={{ height: 60, justifyContent: 'center', padding: '10px' }}
					>
						<Typography
							variant="body2"
							style={{
								marginTop: 10,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: selectedTheme.textColorHigh,
							}}
						>
							{denounceValue.description
								? t(
										`ClassificationTypeDescription.${denounceValue.description}`
									)
								: ''}
						</Typography>
					</Line>
					<TextField
						size="small"
						id="denounce_obs"
						label={t('Classificações.Observações')}
						defaultValue={offerData?.denouncementObservation || denounceObs}
						onChange={
							enableClassification ? handleObservationChange : undefined
						}
						multiline
						rows={6}
						variant="outlined"
						InputLabelProps={{
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						inputProps={{
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								color:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
							'& .MuiInputBase-root': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							width: '100%',
							marginTop: '35px',
						}}
					/>
				</>
			</Column>
		</>
	);

	const renderVerifiedInfo = () => {
		return offerData?.author.authorData.verified?.value ? (
			<Line
				style={{
					justifyContent: 'flex-start',
				}}
			>
				<CheckCircle
					style={{
						color: selectedTheme.success,
						fontSize: 15,
						marginRight: 5,
						marginTop: -2,
					}}
				/>
				<Typography
					variant="body2"
					align="left"
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Loja verificada')}
				</Typography>
			</Line>
		) : (
			<Line style={{ justifyContent: 'flex-start' }}>
				<Cancel
					style={{
						color: selectedTheme.error,
						fontSize: 15,
						marginRight: 5,
					}}
				/>
				<Typography
					variant="body2"
					align="left"
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Loja não verificada')}
				</Typography>
			</Line>
		);
	};

	const renderOfficialStoreInfo = () => {
		return offerData?.author.authorData.official?.value ? (
			<Line style={{ justifyContent: 'flex-end' }}>
				<VerifiedUser
					style={{
						color: selectedTheme.success,
						fontSize: 16,
						marginRight: 5,
					}}
				/>
				<Typography
					variant="body2"
					align="right"
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Loja oficial')}
				</Typography>
			</Line>
		) : (
			<Line style={{ justifyContent: 'flex-end' }}>
				<NewReleases
					style={{
						color: selectedTheme.error,
						fontSize: 16,
						marginRight: 5,
					}}
				/>
				<Typography
					variant="body2"
					align="right"
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Classificações.Loja não oficial')}
				</Typography>
			</Line>
		);
	};

	const renderOfferPriceInfo = () => (
		<Line
			style={{
				height: '100%',
				justifyContent: 'space-between',
			}}
		>
			<Line style={{ columnGap: 10 }}>
				{offerData.offerData.price.value && (
					<>
						<Typography
							variant="body2"
							style={{
								fontWeight: 'bold',
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							}}
						>
							{`${t('Classificações.Preço')}`}:
						</Typography>
						<Typography
							style={{
								fontSize: 18,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{offerData.offerData.price.value.toLocaleString(userData.i18nID, {
								style: 'currency',
								currency: offerData.offerData.currency.value,
								minimumFractionDigits: 2,
							})}
						</Typography>
					</>
				)}
			</Line>
			<Line
				style={{
					columnGap: 10,
					justifyContent: 'flex-end',
				}}
			>
				<Typography
					variant="body2"
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					}}
				>
					{`${t('Classificações.Categoria')}`}:
				</Typography>
				<Typography
					variant="body2"
					style={{
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{offerData.offerCategory}
				</Typography>
			</Line>
		</Line>
	);

	const renderOfferDetails = () => (
		<Column style={{ alignItems: 'center', rowGap: 5 }}>
			<Line
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Column
					key="offerInfo"
					style={{
						height:
							'calc(100vh - 100px - 50px - 55px - 75px - 175px - 3 * 15px - 15px - 90px)', // 100vh - header - footer - renderOfferPriceInfo - renderPostInfo - renderDenounceReason - 3 * padding - author_Info_Inside_This_Card
						width: '100%',
					}}
				>
					<Grid container direction="column">
						{Object.entries(offerData.author.authorData).map(
							(data: any, index) => (
								<React.Fragment key={index}>
									<Line
										key={index}
										style={{
											width: '100%',
											justifyContent: 'space-between',
											marginBottom: 10,
											gap: 10,
										}}
									>
										<OfferToolTip
											arrow
											title={t('ClassificationDetails.' + data[1].key_name)}
										>
											<Typography
												variant="body2"
												style={{
													color:
														selectedTheme.id === 'dark' &&
														selectedTheme.textColorMedium,
													whiteSpace: 'nowrap',
												}}
											>
												<strong>
													{t('ClassificationDetails.' + data[1].key_name)}:
												</strong>
											</Typography>
										</OfferToolTip>
										<OfferToolTip arrow title={data[1].value}>
											<Typography
												variant="body2"
												style={{
													color:
														selectedTheme.id === 'dark' &&
														selectedTheme.textColorHigh,
												}}
											>
												{typeof data[1].value === 'boolean' ? (
													renderBooleanValue(data[1].value)
												) : (
													<ExpandableText
														text={
															typeof renderValueFormat(
																data[1].key_name,
																data[1].value
															) === 'string'
																? renderValueFormat(
																		data[1].key_name,
																		data[1].value
																	)
																: String(
																		renderValueFormat(
																			data[1].key_name,
																			data[1].value
																		) || ''
																	)
														}
														maxLines={100}
														charLimit={40}
													/>
												)}
											</Typography>
										</OfferToolTip>
									</Line>
									{index === 3 && (
										<Divider
											style={{
												width: '100%',
												background:
													selectedTheme.id === 'dark'
														? selectedTheme.footerLine
														: '',
												margin: '10px 0',
											}}
										/>
									)}
								</React.Fragment>
							)
						)}
					</Grid>
				</Column>
			</Line>
		</Column>
	);

	const renderPostInfo = () => (
		<Column style={{ justifyContent: 'center', rowGap: 5 }}>
			<Line
				style={{
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Line
					style={{ width: '30%', justifyContent: 'flex-start', columnGap: 10 }}
				>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Classificações.Fonte')}:
					</Typography>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{offerData.sourceID}
					</Typography>
				</Line>
				<Line
					style={{
						width: '30%',
						justifyContent: 'flex-end',
						columnGap: 10,
						fontWeight: 'bold',
					}}
				>
					<Typography
						variant="body2"
						align="right"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Classificações.Id do autor')}:
					</Typography>
					<Typography
						align="right"
						variant="body2"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{offerData.authorID}
					</Typography>
				</Line>
			</Line>
			<Line
				style={{
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Line
					style={{
						width: '45%',
						justifyContent: 'flex-start',
						columnGap: 10,
					}}
				>
					<Typography
						variant="body2"
						align="right"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Classificações.Atualizado em')}:
					</Typography>
					<Typography
						variant="body2"
						align="right"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{dateFormat(offerData.updated, 'dd/mm/yyyy')}
					</Typography>
				</Line>
				<Line
					style={{
						width: '45%',
						justifyContent: 'flex-end',
						columnGap: 10,
					}}
				>
					<Typography
						variant="body2"
						align="right"
						noWrap
						style={{
							fontWeight: 'bold',
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						}}
					>
						{t('Classificações.Id da oferta')}:
					</Typography>
					<Typography
						variant="body2"
						align="right"
						noWrap
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{offerData.offerID}
					</Typography>
				</Line>
			</Line>
			<FormDivider
				name={''}
				margin="8px 0px 10px 0px"
				width="90%"
				opacity={0.3}
				background={
					selectedTheme.id === 'dark' ? selectedTheme.primaryDark : 'white'
				}
			/>
			<Line
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Column
					style={{
						width: '48%',
						height:
							'calc(100vh - 100px - 50px - 55px - 75px - 175px - 3 * 15px - 15px - 90px)',
					}}
				>
					<Grid container direction="column" style={{ width: '100%' }}>
						<Line
							style={{
								width: '100%',
								justifyContent: 'space-between',
								marginBottom: 10,
							}}
						>
							<Line
								style={{
									justifyContent: 'flex-start',
									width: '100%',
								}}
							>
								<Typography
									variant="body2"
									noWrap
									style={{
										fontWeight: 'bold',
										width: '100%',
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									}}
								>
									{offerData.platformINcountryID.replace('|', ' - ')}
								</Typography>
							</Line>
						</Line>
						<Line
							style={{
								width: '100%',
								justifyContent: 'flex-start',
								columnGap: 10,
								marginBottom: 10,
							}}
						>
							{offerData?.author.authorData.verified && renderVerifiedInfo()}
							{offerData.author.authorData.official ? (
								renderOfficialStoreInfo()
							) : (
								<Typography
									variant="body2"
									align="right"
									style={{
										fontWeight: 'bold',
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorHigh,
										whiteSpace: 'nowrap',
									}}
								>
									{t('Classificações.Status da loja não disponível')}
								</Typography>
							)}
						</Line>
						<Line
							style={{
								width: '100%',
								justifyContent: 'space-between',
								marginBottom: 10,
							}}
						>
							<Line
								style={{
									justifyContent: 'flex-start',
									width: '100%',
								}}
							>
								<Typography
									variant="body2"
									style={{
										fontWeight: 'bold',
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									}}
								>
									{t('Classificações.Autor')}:
								</Typography>
								<IconButton
									size="small"
									style={{
										fontSize: 14,
										transform: 'rotate(45deg)',
										padding: 5,
										marginBottom: -1,
										marginLeft: 5,
									}}
									sx={{
										'&:hover': {
											backgroundColor:
												selectedTheme.id === 'dark' &&
												selectedTheme.primaryLight,
										},
									}}
									href={offerData.author.permalink}
									target="blank"
								>
									<LinkIcon
										style={{
											fontSize: 15,
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorMedium
													: selectedTheme.primary,
										}}
									/>
								</IconButton>
								<OfferToolTip
									title={offerData.author.authorName}
									aria-label="inativo"
									enterDelay={700}
									enterNextDelay={700}
									TransitionComponent={Zoom}
									arrow
								>
									<Box
										maxWidth="220px"
										overflow="hidden"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
									>
										<Link
											href={offerData.author.permalink}
											variant="body2"
											color="inherit"
											target="blank"
											sx={{ textDecoration: 'none' }}
										>
											<Typography
												variant="body2"
												noWrap
												style={{
													color:
														selectedTheme.id === 'dark' &&
														selectedTheme.textColorHigh,
												}}
											>
												{offerData.author.authorName}
											</Typography>
										</Link>
									</Box>
								</OfferToolTip>
							</Line>
							<Line style={{ justifyContent: 'flex-end', width: '33%' }}>
								{offerData.author.stateName ? (
									<OfferToolTip
										title={`${offerData.author.cityName}, ${offerData.author.stateName}`}
										aria-label="inativo"
										enterDelay={700}
										enterNextDelay={700}
										TransitionComponent={Zoom}
										arrow
									>
										<Box
											overflow="hidden"
											textOverflow="ellipsis"
											whiteSpace="nowrap"
										>
											<Line
												style={{
													justifyContent: 'flex-end',
													columnGap: 10,
												}}
											>
												<Typography
													variant="body2"
													noWrap
													style={{
														color:
															selectedTheme.id === 'dark' &&
															selectedTheme.textColorHigh,
													}}
												>
													{`${offerData.author.cityName},`}
												</Typography>
												<Typography
													variant="body2"
													noWrap
													style={{
														color:
															selectedTheme.id === 'dark' &&
															selectedTheme.textColorHigh,
													}}
												>
													{offerData.author?.stateName
														? converteNomeParaSigla(offerData.author.stateName)
														: 'n/d'}
												</Typography>
											</Line>
										</Box>
									</OfferToolTip>
								) : (
									<Typography
										variant="body2"
										align="right"
										noWrap
										style={{
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme.textColorHigh,
										}}
									>
										{offerData.author.cityName !== null
											? `${offerData.author.cityName}`
											: t('Classificações.Localidade não disponível')}
									</Typography>
								)}
							</Line>
						</Line>
						<FormDivider
							name={''}
							margin="11px 0px"
							width="100%"
							opacity={0.3}
							background={
								selectedTheme.id === 'dark'
									? selectedTheme.primaryDark
									: 'white'
							}
						/>
						{offerData &&
							offerData.offerData &&
							Object.entries(offerData.offerData).map((data: any, index) => (
								<React.Fragment key={index}>
									<Line
										style={{
											width: '100%',
											justifyContent: 'space-between',
											marginBottom: 10,
											gap: 10,
										}}
									>
										<OfferToolTip
											arrow
											title={t('ClassificationDetails.' + data[1].key_name)}
										>
											<Typography
												variant="body2"
												style={{
													color:
														selectedTheme.id === 'dark' &&
														selectedTheme.textColorMedium,
													whiteSpace: 'nowrap',
												}}
											>
												<strong>
													{t('ClassificationDetails.' + data[1].key_name)}:
												</strong>
											</Typography>
										</OfferToolTip>

										<OfferToolTip arrow title={data[1].value}>
											<Typography
												variant="body2"
												style={{
													color:
														selectedTheme.id === 'dark' &&
														selectedTheme.textColorHigh,
												}}
											>
												{typeof data[1].value === 'boolean' ? (
													renderBooleanValue(data[1].value)
												) : (
													<ExpandableText
														text={
															typeof renderValueFormat(
																data[1].key_name,
																data[1].value
															) === 'string'
																? renderValueFormat(
																		data[1].key_name,
																		data[1].value
																	)
																: String(
																		renderValueFormat(
																			data[1].key_name,
																			data[1].value
																		) || ''
																	)
														}
														maxLines={100}
														charLimit={40}
													/>
												)}
											</Typography>
										</OfferToolTip>
									</Line>

									{index === 3 && (
										<Divider
											style={{
												width: '100%',
												background:
													selectedTheme.id === 'dark'
														? selectedTheme.footerLine
														: '',
												margin: '10px 0',
											}}
										/>
									)}
								</React.Fragment>
							))}
					</Grid>
				</Column>
				<Column
					style={{
						width: '48%',
						height:
							'calc(100vh - 100px - 50px - 55px - 75px - 175px - 45px - 15px - 90px)',
					}}
				>
					{renderOfferDetails()}
				</Column>
			</Line>
		</Column>
	);

	return (
		<Grid
			item
			xs={7}
			style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
		>
			{/* cardDir01 */}
			<Card
				sx={{
					height: '55px',
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
				}}
			>
				<CardContent style={{ padding: '15px 20px' }}>
					{renderOfferPriceInfo()}
				</CardContent>
			</Card>

			{/* cardDir02 */}
			<Card
				sx={{
					flexGrow: 1,
					flexShrink: 1,
					flexBasis: '32vh',
					transition: 'height 0.4s ease-in-out',
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
					padding: '0px 0px 15px',
				}}
			>
				<CardContent
					style={{ overflowY: 'auto', maxHeight: '100%', overflowX: 'hidden' }}
				>
					{renderPostInfo()}
				</CardContent>
			</Card>

			{/* cardDir03 */}
			<Card
				sx={{
					flexGrow: 2,
					flexShrink: 1,
					flexBasis: '35vh',
					padding: '0px',
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
					border:
						selectedTheme.id === 'dark'
							? `1px solid ${selectedTheme.footerLine}`
							: 'none',
					overflow: 'auto',
				}}
			>
				<CardContent style={{ padding: 0 }}>
					{loading ? (
						<CircularProgress
							size={25}
							style={{ position: 'relative', top: '50%', left: '50%' }}
						/>
					) : (
						renderDenounceReason()
					)}
				</CardContent>
			</Card>
		</Grid>
	);
};
export default RightColumn;
