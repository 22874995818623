import $ from 'jquery';
import React, { ReactNode, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Drawer, Divider, CssBaseline } from '@mui/material';
import { usePO } from '../../utils/POContext';
import TopMenuIcons from './TopMenuIcons';
import MenuItems from './MenuItems';
import BottomMenuIcons from './BottomMenuIcons';
import { useDispatch } from 'react-redux';
import { changeStateMenu } from '../../redux/slices/vertMenuSlice';
import TermsOfUseModal from '../TermsModal';
import { closeAllPanels } from '../../redux/slices/expansibleTableSlice';
// import JIRAIssueCollector from '../JiraIssueCollector';

const drawerWidth = window.innerHeight < 901 ? 60 : 70;

interface Props {
	children: ReactNode;
}

declare global {
	interface Window {
		$: any;
		jQuery: any;
	}
}

window.$ = $;
window.jQuery = $;

const VertMenu: React.FC<Props> = ({ children }: Props) => {
	const { selectedTheme, cursorMode, userData } = usePO();

	const [denseMenu] = useState<boolean>(window.innerHeight < 901);
	const [openMenu, setOpenMenu] = useState(false);
	const [transition, setTransition] = useState(false);
	const [isMouseOver, setIsMouseOver] = useState(false);

	const [openTermsModal, setOpenTermsModal] = useState<boolean>(false);
	const [acceptedTermsModal, setAcceptedTermsModal] = useState<boolean>(
		userData.termsAccepted
	);

	const dispatch = useDispatch();

	useEffect(() => {
		// JIRAIssueCollector();
		dispatch(changeStateMenu(openMenu));
		dispatch(closeAllPanels());
		changeBodyColorbyTheme(selectedTheme.tone);
		// const script = document.createElement('script');
		// script.src =
		// 	'https://offertechoffertech.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3o5b4z/b/2/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=pt-BR&collectorId=2e4935cf';
		// script.async = true;
		// document.body.appendChild(script);
		// return () => {
		// 	document.body.removeChild(script);
		// }; //TODO Script de feefback comentado pois será reposicionado na offerClean

		let timeoutId: NodeJS.Timeout | null = null;

		if (!isMouseOver && openMenu) {
			timeoutId = setTimeout(() => {
				setTransition(true);
				setTimeout(() => {
					setOpenMenu(false);
					setTransition(false);
				}, 200);
			}, 10000); // Delay before close menu
		}

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [isMouseOver, openMenu, dispatch]);

	useEffect(() => {
		acceptedTermsModal ? setOpenTermsModal(false) : setOpenTermsModal(true);
	}, [acceptedTermsModal]);

	const changeBodyColorbyTheme = (theme) => {
		const newBackgroundColor = theme === 'dark' ? '#121212' : '#fff';
		document.documentElement.style.setProperty(
			'--background-color',
			newBackgroundColor
		);
	};

	////TODO Arrumar Tipagem
	const useStyles = makeStyles((theme: any) => ({
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			minHeight: 550,
			background:
				selectedTheme.id === 'dark'
					? selectedTheme?.vertMenuColor
					: selectedTheme?.gradient,
			overflow: 'hidden',
			width: window.innerHeight < 901 ? 200 : 230,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: '200ms',
			}),
			marginLeft: 0,
			zIndex: 1020,
			borderWidth: 0,
		},
		drawerClose: {
			minHeight: 550,
			background:
				selectedTheme.id === 'dark'
					? selectedTheme?.vertMenuColor
					: selectedTheme?.gradient,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: '200ms',
			}),
			overflow: 'hidden',
			width: drawerWidth,
			marginLeft: 0,
			zIndex: 1020,
			borderWidth: 0,
		},
		content: {
			flexGrow: 1,
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			marginTop: 0,
			marginLeft: 0,
		},
		contentShift: {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0, // Margin between content and right Drawer edge
		},
		contentOutput: {
			background: selectedTheme?.background,
			minHeight: '100vh',
			padding: '10px 10px 0px 11px',
			boxShadow: 'inset 2px 0px 3px rgba(0, 0, 0, 0.3)',
		},
	}));

	const classes = useStyles();

	const handleOpenDrawer = () => {
		setTransition(true);
		setTimeout(() => {
			setOpenMenu((prevOpenMenu) => !prevOpenMenu);
			setTransition(false);
		}, 200);
	};

	/** Handling menu auto close */
	const handleMouseOver = () => {
		setIsMouseOver(true);
	};

	const handleMouseLeave = () => {
		setIsMouseOver(false);
	};

	const renderMainContent = () => {
		return (
			<main
				className={clsx(classes.content, {
					[classes.contentShift]: openMenu,
				})}
			>
				<div />
				<div id="outputPersistent" className={classes.contentOutput}>
					{children}
				</div>
			</main>
		);
	};

	const handleChangeAcceptedTerms = (data) => {
		setAcceptedTermsModal(data);
	};

	const handleCloseTermsModal = () => {
		setOpenTermsModal(false);
	};

	return !acceptedTermsModal ? (
		<TermsOfUseModal
			open={openTermsModal}
			handleClose={handleCloseTermsModal}
			handleChangeAcceptedTerms={handleChangeAcceptedTerms}
		/>
	) : (
		<div
			style={{
				display: 'flex',
				overflow: 'hidden',
				cursor: cursorMode || 'inherit',
			}}
		>
			<Drawer
				onMouseEnter={handleMouseOver}
				onMouseLeave={handleMouseLeave}
				variant="permanent"
				anchor="left"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: openMenu,
					[classes.drawerClose]: !openMenu,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: openMenu,
						[classes.drawerClose]: !openMenu,
					}),
				}}
			>
				<TopMenuIcons
					denseMenu={denseMenu}
					handleOpenDrawer={handleOpenDrawer}
					openMenu={openMenu}
					transition={transition}
				/>
				<Divider
					style={{
						margin: denseMenu ? '0px 10px 5px 10px' : '15px 20px 10px 20px',
						background: selectedTheme.id === 'dark' && selectedTheme.footerLine,
						opacity: 0.8,
					}}
				/>
				<MenuItems denseMenu={denseMenu} openMenu={openMenu} />
				<BottomMenuIcons denseMenu={denseMenu} />
			</Drawer>
			<CssBaseline />
			{renderMainContent()}
		</div>
	);
};

export default VertMenu;
