import React, { useState } from 'react';
import './ExpandableText.css';
import { Button, Typography } from '@mui/material';
import { t } from 'i18next';
import { usePO } from '../../utils/POContext';

interface ExpandableTextProps {
	text: string;
	maxLines?: number;
	charLimit?: number;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({
	text,
	maxLines = 1,
	charLimit = 40,
}) => {
	const { selectedTheme } = usePO();
	const [expanded, setExpanded] = useState(false);

	const handleToggle = () => setExpanded(!expanded);

	const shouldShowButton = text.length > charLimit;

	return (
		<div>
			<Typography
				variant="body2"
				className={`expandable-text ${expanded ? '' : 'truncated-text'}`}
				style={{
					WebkitLineClamp: expanded ? 'unset' : maxLines,
					display: '-webkit-box',
					WebkitBoxOrient: 'vertical',
					overflow: 'hidden',
				}}
			>
				{text}
				{shouldShowButton && !expanded && (
					<span className="expandable-text-ellipsis">...</span>
				)}
			</Typography>
			{shouldShowButton && (
				<Button
					size="small"
					className="expandable-text-button"
					onClick={handleToggle}
					sx={{
						textTransform: 'lowercase',
						color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					{expanded
						? t('Classificações.Ver menos')
						: t('Classificações.Ver mais')}
				</Button>
			)}
		</div>
	);
};

export default ExpandableText;
