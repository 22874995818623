import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import BannerCombatePirataria from '../../../../assets/images/svg/BannerBR/banner_po_combate_a_pirataria_background_.svg';
import BannerPerfisSites from '../../../../assets/images/svg/BannerBR/banner_po_perfis_sites_falsos_background.svg';
import BannerProtecaoComercial from '../../../../assets/images/svg/BannerBR/banner_po_prot_comercial_online_background.svg';
import TopMainContent from './TopMainContent';
import BannerCombatePiratariaES from '../../../../assets/images/svg/BannerES/espanhol_banner_po_combate_a_pirataria_background.svg';
import BannerPerfisSitesES from '../../../../assets/images/svg/BannerES/espanhol_banner_po_perfis_sites_falsos_background.svg';
import BannerProtecaoComercialES from '../../../../assets/images/svg/BannerES/espanhol_banner_po_prot_comercial_online_background.svg';
import MiddleMainContent from './MiddleMainContent';
import BottomMainContent from './BottomMainContent';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Banner.css';
import { t } from 'i18next';
import BannerCombatePiratariaEN from '../../../../assets/images/svg/BannerEN/ingles_banner_po_combate_a_pirataria_background.svg';
import BannerPerfisSitesEN from '../../../../assets/images/svg/BannerEN/ingles_banner_po_perfis_sites_falsos_background.svg';
import BannerProtecaoComercialEN from '../../../../assets/images/svg/BannerEN/ingles_banner_po_prot_comercial_online_background.svg';
import { Notifications } from '@mui/icons-material';

interface Props {
	loading: boolean;
	pieChartData: any;
	DetectedProfiles: any;
	ProfileFollowers: any;
	TotalViolations: any;
}

const ClientNewDashboard: React.FC<Props> = ({
	loading,
	pieChartData,
	DetectedProfiles,
	ProfileFollowers,
	TotalViolations,
}: Props) => {
	const { selectedTheme, userData } = usePO();

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const renderBanner = () => {
		const settings = {
			dots: true,
			arrows: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 4000,
			lazyLoad: true,
		};

		let banners;
		if (userData.i18nID === 'es-es') {
			banners = [
				{ src: BannerCombatePiratariaES, alt: 'Combate a la Piratería' },
				{ src: BannerPerfisSitesES, alt: 'Perfiles de Sitios Falsos' },
				{
					src: BannerProtecaoComercialES,
					alt: 'Protección Comercial en Línea',
				},
			];
		} else if (userData.i18nID === 'en-us') {
			banners = [
				{ src: BannerCombatePiratariaEN, alt: 'Fight Against Piracy' },
				{ src: BannerPerfisSitesEN, alt: 'Fake Site Profiles' },
				{ src: BannerProtecaoComercialEN, alt: 'Online Commercial Protection' },
			];
		} else {
			banners = [
				{ src: BannerCombatePirataria, alt: 'Combate à Pirataria' },
				{ src: BannerPerfisSites, alt: 'Perfis de Sites Falsos' },
				{ src: BannerProtecaoComercial, alt: 'Proteção Comercial Online' },
			];
		}

		return (
			<div className="IntroductionContainer">
				<Slider {...settings}>
					{banners.map((banner, index) => (
						<div key={index} style={{ width: '100%' }}>
							<img src={banner.src} alt={banner.alt} />
						</div>
					))}
				</Slider>
			</div>
		);
	};

	const renderTopContent = () => {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TopMainContent />
				</Grid>
			</Grid>
		);
	};

	const renderMiddleContent = () => {
		return (
			<Grid container spacing={2} style={{ height: '100%' }}>
				<Grid item xs={12} style={{ height: '100%' }}>
					<MiddleMainContent
						loading={loading}
						pieChartData={pieChartData}
						DetectedProfiles={DetectedProfiles}
						ProfileFollowers={ProfileFollowers}
						TotalViolations={TotalViolations}
					/>
				</Grid>
			</Grid>
		);
	};

	const renderBottomContent = () => {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<BottomMainContent />
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			<div
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					margin: '11px 0px -1px',
				}}
			>
				{renderBanner()}
			</div>
			<Line
				style={{
					justifyContent: 'space-between',
					padding: '10px 0px 0px',
					marginBottom: 10,
				}}
			>
				<Typography
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{`${t('Inicio.Dashboard.Que bom te ver,')} ${userData?.name}${'!'}`}
				</Typography>
				<Button
					variant="contained"
					disabled
					style={{
						background: selectedTheme.primaryDark,
						border: handleBorderButton(),
						gap: '10px',
						opacity: 0.6,
						cursor: 'not-allowed',
						pointerEvents: 'auto',
					}}
					title={t(
						'Inicio.Dashboard.Estamos aperfeiçoando esta funcionalidade. Em breve estará disponível!'
					)}
				>
					<Notifications
						fontSize="small"
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
						}}
					/>
					<Typography
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							fontSize: '10',
						}}
					>
						{t('Inicio.Dashboard.Avisos')}
					</Typography>
				</Button>
			</Line>
			<Line>{renderTopContent()}</Line>
			<Line style={{ marginTop: 15, height: 'auto', marginBottom: 15 }}>
				{renderMiddleContent()}
			</Line>
			<Line
				style={{
					justifyContent: 'space-between',
					padding: '10px 0px 0px',
					marginBottom: 10,
				}}
			>
				<Typography
					style={{
						fontWeight: 'bold',
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
					}}
				>
					{t('Inicio.Dashboard.Conteúdos selecionados para você')}
				</Typography>
			</Line>
			<Line style={{ paddingBottom: 20 }}>{renderBottomContent()}</Line>
		</>
	);
};

export default ClientNewDashboard;
